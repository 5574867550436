import React from 'react';

const SingleServiceThree = (props) => {
	return(
        <div className={props.itemClass}>
            <div className="services-icon">
                <img 
                    src={props.serviceImage} 
                    alt={props.Title}
                />
            </div>
            <div className="services-content">
                <h2 className="services-title">
                 
                    {props.Title}
                    
                </h2>
                <p className="desc">
                    {props.Text}
                </p>
                
            </div>
        </div>
	)
}

export default SingleServiceThree