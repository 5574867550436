import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';


import Newsletter from '../../Common/Newsletter';

import footerLogo1 from '../../../assets/img/logo/logo.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p>At Metaqualt IT Solutions, We provide a range of services including the development of mobile and web applications.</p>
                            </div>
                            <ul className="footer-social md-mb-30">
                                <li>
                                    <a href="https://www.facebook.com/"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/"><i className="fa fa-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/"><i className="fa fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                            <h3 className="widget-title heading-line">Our Services</h3>
                            
                            <ul className="site-map">
                                <li><Link to="./web-development">Web Development</Link></li>
                                <li><Link to="./wordpress-development">Wordpress Development</Link></li>
                                <li><Link to="./ux-ui-design">UI/UX & Graphic Design</Link></li>
                                <li><Link to="./mobile-app-development">Mobile App Development</Link></li>
                                <li><Link to="./javaScripts-development">JavaScript Development</Link></li>
                                <li><Link to="./reactJs-development">React Js Development</Link></li>
                                <li><Link to="./angularJs-development">AngularJS Development</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                            <h3 className="widget-title">Our Solution</h3>
                            <ul className="site-map">
                                <li><Link to="./fintech-app-solution">Fintech App Solutions</Link></li>
                                <li><Link to="./ecommerce-solutions">E-Commerce App Solutions</Link></li>
                                <li><Link to="./business-app-solution">Business App Solution</Link></li>
                                <li><Link to="./fitness-wellness-app-solutions">Fitness App Solutions</Link></li>
                                <li><Link to="./healthcare-app-solution">Healthcare App Solutions</Link></li>
                                <li><Link to="./travel-app-solutions">Travel App Solutions</Link></li>
                                <li><Link to="./social-media-app">Social Media App Solutions</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                            <h3 className="widget-title">Contact Info</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">374 FA Tower, William S Blvd 2721, IL, USA</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <a href="tel:(+91) 999–5554">(+91) 999–5554</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                    <a href="mailto:info@metaqualt.com">info@metaqualt.com</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        Opening Hours: 10:00 - 18:00
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;