import React from 'react';
import WeServes from './it-services'
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Process/SingleProcess';
import Brand from '../../components/Common/Brand';
import WhyChoose from './whychooseus';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import TechSlider from './TechSlider';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';
import roundImg from '../../assets/img/about/social-app.webp';


// Service Icons

import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';


const SocialMediaAppMain = () => {

    return (
        <React.Fragment>  
            {/* <!-- banner section end --> */}
            <div id="rs-about" className="rs-about style3 pt-150 pb-0 md-pt-60">
                <div className="container">
                    <div className="row">
                    
                        <div className="col-xl-7 col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 pb-20 pt-50 md-pt-10 md-pb-20"
                                subtitleClass="sub-text style-bg"
                                subtitle="WHO WE ARE"
                                titleClass="title mb-25"
                                title="Develop Enterprise Social Media Apps with Us & Stay Ahead of Your Competition."
                                descClass="desc pb-15"
                                description="Developing innovative and interactive social media apps that engage users and keep them up-to-date on their loved ones' lives is what we do. We develop robust and intuitive social media apps for our clients."
                                secondDescClass="mb-10 pb-16"
                                secondDescription="Our social media application development services help clients harness the benefits of social media. With our expertise in social media app development, we are able to offer our clients globally future-ready solutions. For our clients, we offer customized social media app development solutions that are designed to keep them ahead of the competition by providing the newest features and a fantastic user experience. With a feature-rich and user-friendly social networking app, we engage global audiences and improve real-time connections with top brands and enterprises." 
                            /> 
                             {/* Section Title Start */}
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-12 md-mb-30">
                            <div className="text-center pt-70 md-pt-30 sm-pt-15 pb-15">
                                <img className="main" src={roundImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                </div>
            </div>
            {/* about-area-start */}  

            {/* counter area start */}
            <CounterHomeFive />
            {/* counter area end */}

            {/* about-area-start */}
			<WeServes />
			{/* about-area-end */}

            {/* TechSlider-start */}
            <TechSlider />
            {/* TechSlider-start */}

            {/* why choose us area start */}
            <WhyChoose />
            {/* why choose us area start */}

            {/* rs-servicess-area-start */}
            <div className="rs-services bg5 style8 pt-50 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
            <CTA
				 ctaSectionClass="rs-cta text-center style1 cta-bg1 pt-80 pb-80"
                 ctaTitleClass="epx-title"
                 ctaTitle="Build Your Professional Social Media Apps Today."
                 ctaTextClass="exp-text"
                 ctaText="Cutting-edge technologies are part of our services for developing social networking apps."
                 ctaButtonClass="readon learn-more"
                 ctaButtonLink="./contact"
                 ctaButtonText="Contact Us"
			/>
			{/* newsletter-area-end */}

			
            {/* working-process-area-start */}
            <div className="rs-process style2 pt-80 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title2"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}           

            {/* brand-area-start */}
			<Brand />
			{/* brand-area-end */}

        </React.Fragment>
    )
}

export default SocialMediaAppMain;