

// Service Image Icons
import mainIcon1  from '../../assets/img/about/BG-22.png';
import mainIcon2 from '../../assets/img/about/BG-22.png';
import mainIcon3 from '../../assets/img/about/BG-22.png';
import mainIcon5 from '../../assets/img/about/BG-22.png';
import bannerEight from '../../assets/img/banner/banner-8.png';

const WhyChoose = () => {
    const myStyle={
        backgroundImage: `url(${bannerEight})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right center'
    };
    return (
        <div id="" className="mireview-section pt-0 pb-0 mt-10 mb-10" style={myStyle}>
            <div className="container-fluid">
              
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-0">
                        <div class="game-logos">
                           <div class="bg-img">
                                <div className="text-center">
                                    <img className="main" src={mainIcon1} alt=""/>
                                </div>
                            </div>
                            <div class="logo-name">
                                <div className="Title_text">Smart Code</div>
                                <div className="text">Whenever possible, avoid all technical risks to ensure fault-tolerant code. Before writing code, we consider how users may react.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-0">
                        <div class="game-logos">
                           <div class="bg-img">
                                <div className="text-center">
                                    <img className="main" src={mainIcon1} alt=""/>
                                </div>
                            </div>
                            <div class="logo-name">
                                <div className="Title_text">Reusable Code</div>
                                <div className="text">Redundancy and development time can be reduced by reusing code. By having reusability of the code, we optimize the code. </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-0">
                        <div class="game-logos">
                           <div class="bg-img">
                                <div className="text-center">
                                    <img className="main" src={mainIcon1} alt=""/>
                                </div>
                            </div>
                            <div class="logo-name">
                                <div className="Title_text">Beautiful Code</div>
                                <div className="text">Beautiful Code is the code that seems professional and understandable. It is written as per the widely accepted coding standard.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-0">
                        <div class="game-logos">
                           <div class="bg-img">
                                <div className="text-center">
                                    <img className="main" src={mainIcon1} alt=""/>
                                </div>
                            </div>
                            <div class="logo-name">
                                <div className="Title_text">Stable Code</div>
                                <div className="text">Every product we release is thoroughly tested for code stability. Code and products are tested using a variety of testing tools.</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;