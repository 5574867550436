import React from 'react';
import { Link } from 'react-router-dom';

const CaseStyleFive = (props) => {
    const { caseClass } = props;
    return (
        <div className={caseClass ? caseClass : 'project-item'}>
            <div className="project-img">
                <img
                    src={props.caseImage}
                    alt={props.caseTitle}
                />
            </div>
            <div className="project-content">
                <div className="vertical-middle">
                    <div className="vertical-middle-cell">
                        <Link to={props.solutionURL}>
                            <h3 className="title">{props.caseTitle}</h3>
                            <span className="category">{props.caseCategory}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default CaseStyleFive;