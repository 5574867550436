import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon90 from '../../assets/img/service/style2/main-img/90.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services  style3 pt-80 pb-50 md-pt-80 md-pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Solution"
                    titleClass="title testi-title"
                    title="Choosing Us for Healthcare Mobile App"
                    descClass="desc"
                    description="As one of the best mobile health app development companies, we transform your ideas into reality using software, mobile apps, and web solutions. We offer scalable and patient-focused mobile healthcare application development services that can assist you in developing flawless and interactive apps that can offer your users a top-notch experience. It makes it easier for users to check into their profiles, save their reports, and share them."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon2}
                            hoverIcon={mainIcon2}
                            Title="Experienced Developers"
                            Text="We have a team of professional & experienced health app developers who are inclined towards delivering the best quality of apps."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Custom & Intuitive Experiences"
                           Text="Instead of following a one-size-fits-all approach, we build each of the apps uniquely as per the business requirements of the client."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Procedure"
                            Text="Using the Agile methodology, we conduct frequent scrum events to optimize practices and methods."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon90}
                            hoverIcon={mainIcon90}
                            Title="Round-The-Clock Support"
                           Text="We, being a responsible Healthcare App Development company understand that emergencies can arise anytime & so we profile 24/7 support to our clients."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Cost-effectiveness"
                           Text="You can be assured that you would get cost-effective mobile app development services."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Quick Delivery"
                            Text="Our company is known for delivering mobile apps on time, and within your budget."
                        />
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default WhyChoose;