import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

import valueBg from'../../assets/img/banner/value-bg.png';
import aboutImg1 from '../../assets/img/about/fitness.webp';

const TechSlider = () => {
    return (
        <React.Fragment>
            <div className="style3 pt-80 pb-50 md-pt-80 md-pb-80" style={{
            backgroundImage: 'url('+valueBg+')',
            backgroundSize: "cover",
            backgroundPosition:"left bottom"
        }}>
            <div className="container relative">
                <div className="sec-left">
                    <h2 className="title ">What's We Provide</h2>
                </div>
                <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-10"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 "
                                    title="Fitness App Solutions"
                                    descClass="desc "
                                    description="As a result of our company's efforts, we have helped clients boost their sales over the years. With our advanced wellness & fitness apps, we make the lives of personal trainers, nutritionists & dietitians easier. By using our top-notch fitness software development solutions, their clients can now receive efficient & prompt service. Using sophisticated technology, we develop fitness apps with unmatched features."
                                />
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Schedule Fitness Level</li>
                                        <li className="">Workout Clock/Calendar</li>
                                        <li className="">Health Guide For Workout</li>
                                        <li className="">Timer Clock</li>
                                        <li className="">Health Planner</li>
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Activity Tracking</li>
                                        <li className="">Video And Tutorial</li>
                                        <li className="">Health & Fitness Forum</li>
                                        <li className="">Multi-Lingual</li>
                                        <li className="">Social Media Login</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default TechSlider;