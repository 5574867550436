import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-50 pb-50">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our UI & UX Design Services"
                        descClass="desc mb-25"
                        description="UI/UX is just an umbrella term; there is a lot more inside it which is going to define your brand as perceived by your website and app users. Hire UX designers that can help you in leveraging these services and improve overall navigation on your product.  "
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Market Research & Analysis"
                                 />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Front-end Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Fully Functional UX/UI"
                                 />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Concept Presentation"
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="UI Testing Process"
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Prototype Testing "
                                 />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default OurServices;