import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/phpDevelopment/SingleServiceSix';

// Service Image Icons
import serviceImg63 from '../../assets/img/service/style2/main-img/63.png';
import serviceImg57 from '../../assets/img/service/style2/main-img/57.png';
import serviceImg58 from '../../assets/img/service/style2/main-img/58.png';
import serviceImg59 from '../../assets/img/service/style2/main-img/59.png';
import serviceImg60 from '../../assets/img/service/style2/main-img/60.png';
import serviceImg64 from '../../assets/img/service/style2/main-img/64.png';
import serviceImg61 from '../../assets/img/service/style2/main-img/61.png';
import serviceImg62 from '../../assets/img/service/style2/main-img/62.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-60 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="Ecommerce Industry"
                    titleClass="title new-title"
                    title="Discover the Powerful Tide of Our Mobile and Web E-Commerce Services."
                    descClass="desc"
                    description="We have a dedicated team of developers that build an extensive range of eCommerce applications for our esteemed customers worldwide. Our goal is to deliver the best solutions that provide an edge over your competitors. We work closely with the client during the project to ensure they are informed about every step of the process. Providing custom eCommerce app solutions for increasing revenue in online store e-commerce, we are a leading solution provider for enhancing sales and brand awareness."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg57}
                            Title="B2B eCommerce App"
                            
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg58}
                            Title="B2C eCommerce App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg63}
                            Title="mCommerce Store" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg64}
                            Title="Multi-Vendor App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg59}
                            Title="Auction & Bidding"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg60}
                            Title="B2B-B2C Marketplace"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg61}
                            Title="Dropshipping App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg62}
                            Title="Wholesaling App"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

