import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon48 from '../../assets/img/service/style2/main-img/6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services  style3 pt-80 pb-50 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Services"
                    titleClass="title testi-title"
                    title="We Are A Leading Provider Of Ecommerce App Development Services."
                    descClass="desc"
                    description="Our company is one of the leading providers of eCommerce app development services, offering exceptional on-demand eCommerce applications to reach out to your target audience. Customer loyalty is boosted by the mobile apps we develop. You will save time and money with our eCommerce solutions. Apps that we create are geared towards success by making use of the right plugins. We are a one-stop shop for all your eCommerce needs. eCommerce solutions are just one part of what we do; we help businesses succeed!"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Customer Satisfaction"
                            Text="As per your requirements, we deliver user-friendly, scalable, secure & new business solutions that are user-friendly, scalable & secure."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Eye-Catching User Experience"
                            Text="To enhance the long-term customer interaction with your company, we deliver an eye-catching user experience."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon48}
                            hoverIcon={mainIcon48}
                            Title="Competitive Pricing "
                           Text="The lowest pricing in our segment is achieved by combining cost-effectiveness with amazing quality."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Flexible Engagement Models"
                           Text="By offering flexibility in engagement and hiring models, we don't force clients to work under rigid and strict parameters."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Integrity & Transparency"
                           Text="Our team respects your ideas & vision, provides you with every detail of your project regularly & considers your valuable advice as needed."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Dedicated Teams"
                           Text="We are backed up with a team of skilled and top e-Commerce developers who deliver future-ready solutions."
                        />
                    </div>
                    
                </div>
            </div>
           
        </div>
    );
}

export default WhyChoose;