import React  from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

import bannerEight from '../../assets/img/bg/bg-4.jpg';

const CallUs = () => {
    const myStyle={
        backgroundImage: `url(${bannerEight})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right center'
    };
    return (
        <div className="relative pt-80 mb-50"  style={myStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-1 col-md-1 col-xs-12"></div>
                    <div className="col-lg-10 pb-50 md-pb-60">
                        <div className="rs-contact-box text-center">
                           <SectionTitle
                                sectionClass="sec-title3"
                                subtitleClass="sub-text style-bg text-center"
                                subtitle="CALL US 24/7"
                                titleClass="title text-center"
                                title="Boosting Growth With a Trusted Tech Partner!"
                                descClass="desc"
                                description="Get assistance from the top IT solution provider company & turn your project idea into reality. "
                                secondDescClass="desc pb-16"
                                secondDescription="Our representative will reply you shortly."
                           />
                            <div className="btn-part mt-20">
                                <Link className="readon learn-more" to="/contact">Start Conversation</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-xs-12"></div>
                </div>
            </div>
            {/* counter area start */}
            <CounterHomeFive />
                {/* counter area end */}
        </div>
    );
}

export default CallUs;