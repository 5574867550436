import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/phpDevelopment/SingleServiceSix';

// Service Image Icons
import serviceImg66 from '../../assets/img/service/style2/main-img/66.png';
import serviceImg67 from '../../assets/img/service/style2/main-img/67.png';
import serviceImg68 from '../../assets/img/service/style2/main-img/68.png';
import serviceImg69 from '../../assets/img/service/style2/main-img/69.png';
import serviceImg70 from '../../assets/img/service/style2/main-img/70.png';
import serviceImg71 from '../../assets/img/service/style2/main-img/71.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-30 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="Wellness & Fitness Industry"
                    titleClass="title new-title"
                    title="Our Fitness App Development Services Will Help Your Business To Fly."
                    descClass="desc"
                    description="As a one-stop fitness app development company, we can provide customized solutions based on the fitness sector's requirements. You can now impress fitness enthusiasts and increase their numbers with the help of our smart wellness and fitness app development services. We design our highly functional and feature-rich apps in such a way that they effectively meet the diverse requirements of clients. By providing exceptional app development services, we will not only meet our clients' expectations but also help them increase profits and return on investment."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-2 col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg66}
                            Title="Fitness Tracking App"
                            
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg67}
                            Title="Wearable Fitness App"
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg68}
                            Title="Yoga Centers App" 
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg69}
                            Title="Nutrition/Diet App"
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg70}
                            Title="Personal Trainers App"
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg71}
                            Title="Activity Tracking App"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

