import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/about/vue-main-abut.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from '../front-end-development/Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import WhyChoose from '../php-development/whychooseus';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain16 from '../../assets/img/service/style2/main-img/16.png';
import imgMain17 from '../../assets/img/service/style2/main-img/17.png';
import imgMain21 from '../../assets/img/service/style2/main-img/21.png';
import imgMain23 from '../../assets/img/service/style2/main-img/23.png';
import imgMain22 from '../../assets/img/service/style2/main-img/22.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon24 from '../../assets/img/service/style2/main-img/24.png';
import serviceIcon25 from '../../assets/img/service/style2/main-img/25.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

// Service Icons
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const AngularJsDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
             <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center pt-60">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="By using Vue.JS, We Develop High Quality Front-End Apps."
                                descClass="desc pb-5"
                                description="VueJS solutions are the best when it comes to interactive UI. A VueJS web developer leverages the full swath of features that VueJS offers, such as JavaScript libraries, component-composition caching, and server-side declarative rendering, to develop compelling user experiences. We specialize in developing single-page web apps, as compared to Native, hybrid-Phonegap or Cordova apps."
                                secondDescClass="desc pb-16"
                                secondDescription="Our VueJS development company won't stop until our projects reach their full potential. We accept challenges that result in results within the project's time and budget."
                            />
                        </div>
                        
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="We Provide"
                        titleClass="title pb-10"
                        title="Professional Excellence and Next-Gen VueJS Development"
                        descClass="desc"
                        description="Take advantage of vuejs web development services to build applications ahead of their time at an affordable price and join the web app bandwagon."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6  col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain16}
                                    HoverImg={imgMain16}
                                    Title="Custom App Development"
                                    Text="The Vue.JS web development services we provide include consultation, planning, development, and maintenance.."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain17}
                                    HoverImg={imgMain17}
                                    Title="One Page Application Development"
                                    Text="Make features-rich & high-performance single-page applications with the technology that is most loved by the technology world."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain22}
                                    HoverImg={imgMain22}
                                    Title="3rd Party Integration"
                                    Text="Vue.JS web applications are enhanced with the integration of third party APIs, resulting in more scalability and flexibility."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain21}
                                    HoverImg={imgMain21}
                                    Title="Enterprise Web Applications"
                                    Text="The Vue.JS software development company we work with has an expert team of developers who build user-friendly, secure, scalable & scalable enterprise web applications."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain23}
                                    HoverImg={imgMain23}
                                    Title="Real-time App Development"
                                    Text="Adaptable web applications that respond instantly to user input. We deliver high-performance and component-rich web applications built using the Vue.JS framework."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center mt-10 mb-10"
                                     titleClass="title pb-10 fz-36"
                                    title="Our Professional Experts Are Inclined Towards Developing Robust Front-end With AngularJSs"
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-60"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why Choose Us for Development?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Proven Expertise"
                            Text="Experience developing user-centric web applications using Vue.JS, resulting in unrivaled user-experiences."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Customer-Centric" 
                            Text="We maintain our clients' websites consistently and proactively after their websites have been developed." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon24}
                            Title="Year-long Experience" 
                            Text="We have years of experience developing feature-rich JavaScript applications in the blink of an eye." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon25}
                            Title="Result-Drive" 
                            Text="We provide customized solutions tailored to your industry's individual needs, giving you a competitive edge." 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-services-area-start */}
            <WhyChoose /> 
            {/* rs-services-area-start */}

            {/* rs-servicess-area-start */}
            <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated Vue.JS Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default AngularJsDevelopment;