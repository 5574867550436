import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import SingleProcess from '../../components/Process/SingleProcess';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import WhyChoose from './whyChoose';

import bannerImg from '../../assets/img/banner/sf-development.webp';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain103 from '../../assets/img/service/style2/main-img/103.png';
import imgMain104 from '../../assets/img/service/style2/main-img/104.png';
import imgMain105 from '../../assets/img/service/style2/main-img/105.png';
import imgMain102 from '../../assets/img/service/style2/main-img/102.png';
import imgMain106 from '../../assets/img/service/style2/main-img/106.png';
import imgMain107 from '../../assets/img/service/style2/main-img/107.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

import phpDvelop from '../../assets/img/banner/phpDevelop.png';
import laravelDevelop from '../../assets/img/banner/laravelDevelop.png';
import codeDevelop from '../../assets/img/banner/codeDevelop.png';
import DesignDevelop from '../../assets/img/banner/DesignDevelop.png';


const WebDevelopment = () => {
    return (
        <React.Fragment>
        {/* rs-servicess-area-start */}    
        <div className="rs-about pt-150 pb-50 md-pt-60">
            <div className="container">
                <div className="row pb-0">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            <img className="main" src={bannerImg} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 pl-20 md-pl-5">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title2 mb-0"
                            subtitleClass="sub-text style-bg"
                            subtitle="What We Do"
                            titleClass="title pb-25"
                            title="We Are Creates Robust Websites & Apps"
                            descClass="desc pb-5"
                            description="You Dream It, We Make It Happen!"
                            secondDescClass="desc pb-16"
                            secondDescription="We develop robust, secure, custom web applications with high scalability as a leading web development company. As per the requirement of a product, we use open source technology to create a high-quality product. As a leading web development company, we help our clients stand out in this digital age. We have highly skilled web developers who have upgraded to various web technologies, such as ReactJS, Angular, Node.js, Laravel, Golang and many others, to provide a customer-centric approach for our businesses. Our goal is to satisfy your clients, which can be achieved by customizing the app to reflect your specifications and preferences."
                        />
                    </div>
                </div>
                <div className="shape-image">
                    <img className="top dance" src={effectImg2} alt="" />
                    <img className="bottom dance" src={effectImg3} alt="" />
                </div>
                {/* counter area start */}
                <CounterHomeFive />
                {/* counter area end */}
            </div>
        </div>
        {/* rs-servicess-area-end */} 
           
        {/* rs-servicess-area-start */}
        <div className="rs-process style6 pt-50 pb-50">
            <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="Most Loved Web Development Technologies"
                        descClass="desc"
                        description="We make use of the most innovative and cutting-edge technologies to deliver you a solution ahead of its time."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-30">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <div className="text-left pb-15">
                                    <img className="main" src={phpDvelop} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-10"
                                        titleClass="title pb-10 fz-28"
                                        title="PHP Development"
                                        descClass="desc"
                                        description="Create websites and apps that stand out with the power of PHP. Has years of experience in a diversity of PHP web development niches, boasting a team of highly skilled PHP developers." 
                                    />
                                    {/* Section Title End */}
                                    <div className="btn-part">
                                        <Link className="readon learn-more" style={{paddingTop : 8, paddingBottom : 8 }} to="./php-development">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 mb-30">
                                <div className="text-left pb-15">
                                        <img className="main" src={laravelDevelop} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-10"
                                        titleClass="title pb-10 fz-28"
                                        title="Laravel Development"
                                        descClass="desc"
                                        description="Laravel development services offer numerous benefits. Various bundles are available & it's easy to use. Developers can build attractive and modern websites with its migration options."  
                                    />
                                    <div className="btn-part">
                                        <Link className="readon learn-more" style={{paddingTop : 8, paddingBottom : 8 }} to="./laravel-development">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 mb-30">
                                <div className="text-left pb-15">
                                    <img className="main" src={codeDevelop} alt=""/>
                                </div>
                                <div className="box-shadow">
                                     <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-28"
                                        title="Codeigniter Development"
                                        descClass="desc"
                                        description="With CodeIgniter, PHP-based websites can be built. Building projects using it is faster than starting from scratch. Despite the library's many features, its interface is simple to use. With CodingIgniter, users are able to focus on creating."  
                                    />
                                    <div className="btn-part">
                                        <Link className="readon learn-more" style={{paddingTop : 8, paddingBottom : 8 }} to="./codeigniter-development">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-30">
                                <div className="text-left pb-15">
                                        <img className="main" src={DesignDevelop} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-28"
                                        title="UI/UX Web Design"
                                        descClass="desc"
                                        description="An engaging & attractive website relies heavily on the UI/UX design. UI/UX developers at our company are well-versed in designing feature-rich, immersive sites for small and large-scale companies alike."  
                                    />
                                    <div className="btn-part">
                                        <Link className="readon learn-more" style={{paddingTop : 8, paddingBottom : 8 }} to="./ux-ui-design">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        {/* rs-services-area-start */}


            {/* rs-servicess-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our services are at the forefront"
                        titleClass="title pb-10"
                        title="Leading Web Development Company Delivers Strategic Vision To Reality"
                        descClass="desc"
                        description="Whether it's far approximately present day developments or small details, from modern principles to eye attractive design, you could depend upon us. We paintings collectively to supply an enjoy that delights and regulations the opposition with present of expanded loyalty and worldly-engagement. We supply your customers a swish and elegant internet site or net application."
                        effectClass="heading-line"
                     />
                        <div className="row">
                            <div className="col-lg-4 mb-30">
                                <SingleService 
                                    itemClass="services-item light-purple-bg"
                                    serviceImage={imgMain102}
                                    Title="Custom Web Development"
                                    Text="We are a leading developer of dynamic and custom websites in this highly competitive market. Developing reliable interactive experiences is our team's responsibility."
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <SingleService
                                    itemClass="services-item light-purple-bg"
                                    serviceImage={imgMain104}
                                   Title="CMS Website Development"
                                    Text="WordPress, Drupal, and other CMS can be built using .NET & PHP. We can streamline your internal processes & workflows in a complex business environment."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <SingleService
                                    itemClass="services-item light-purple-bg"
                                    serviceImage={imgMain106}
                                    Title="Enterprise Web Development"
                                    Text="We have extensive experience building highly complex, yet ROI-generating websites. Whether it is a B2B, B2C, intranet, or media website, we can help you with it."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <SingleService
                                    itemClass="services-item light-purple-bg"
                                    serviceImage={imgMain105}
                                    Title="Website Support & Maintenance"
                                    Text="Among other things, we provide support and maintenance services to keep the site up-to-date and fresh by implementing ongoing changes, adding new features & managing the server."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <SingleService
                                    itemClass="services-item light-purple-bg"
                                    serviceImage={imgMain103}
                                    Title="eCommerce Web Development"
                                    Text="Our eCommerce websites are built with a sales-driven philosophy. Shopify, Magento, Bigcommerce, PHP, NET, open source, PHP developers at our company specialize in developing eCommerce websites."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <SingleService
                                    itemClass="services-item light-purple-bg"
                                    serviceImage={imgMain107}
                                    Title="OpenSource Web Development"
                                    Text="Wordpress, Drupal, and Joomla open-source platforms can help us to build an easy and quick website. Additionally, we deliver hassle-free security and version updates to your clients."
                                    btnClass='d-none'
                                />
                            </div>
                        </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            {/* rs-services-area-start */}
            <WhyChoose />
            {/* rs-services-area-start */}

             {/* rs-servicess-area-start */}
             <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire The Dedicated Web Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default WebDevelopment;