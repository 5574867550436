import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcessTwo from '../../components/Process/SingleProcessTwo';

import shapeImg from'../../assets/img/process/circle.png';
import valueBg from'../../assets/img/banner/value-bg.png';

const Mission = () => {

    return (
        <div style={{
            backgroundImage: 'url('+valueBg+')',
            backgroundSize: "cover",
            backgroundPosition:"left bottom"
          }}>
            <div className="rs-process modify1 pt-50 pb-50">
                <div className="shape-animation hidden-sm">
                    <div className="shape-process">
                        <img className="dance2" src={shapeImg} alt="images" />
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 md-text-center pr-40 md-pr-14 md-pb-80">
                            <SectionTitle
                                sectionClass="sec-title2 mb-30 md-pl-30 md-pr-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="ABOUT US"
                                titleClass="title mb-5"
                                title="Our Values In Industries"
                                descClass="desc pb-2"
                                description="Values are not only beliefs, but promises as well. Our organization's DNA is created by them. Maintain a constant learning process, deliver intrinsic value, remain focused, think agile, and be who you say you are. Our culture is built on these values. Every task we work on is guided by these values."
                            />
                            <div className="btn-part mt-40">
                                <Link className="readon started" to="/contact">Contact Us</Link>
                            </div>
                        </div>
                        <div className="col-lg-8 sm-pl-40 sm-pr-20">
                            <div className="row">
                                <div className="col-md-6 mb-70 ">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c1"
                                        prefixClass="number-area"
                                        prefix="1"
                                        titleClass="title"
                                        Title="Our Vision"
                                        descClass="number-txt"
                                        Description="In order to serve global clients with benchmark quality, fast time to market, and competitive cost advantage, we provide groundbreaking technology solutions with out-of-the-box approaches in development and design services."
                                    />
                                </div>
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c2"
                                        prefixClass="number-area green-bg"
                                        prefix="2"
                                        titleClass="title"
                                        Title="Mission"
                                        descClass="number-txt"
                                        Description="The mission of our company is to provide innovative & reliable products that meet the needs of our clients with almost a high level of quality and unwavering business ethics. We emphasize using agile methodologies to achieve this task. "
                                    />
                                </div>
                                <div className="col-md-6 sm-mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c4"
                                        prefixClass="number-area plum-bg"
                                        prefix="3"
                                        titleClass="title"
                                        Title="Quality"
                                        descClass="number-txt"
                                        Description="In every effort, we encourage employees to be responsible and committed to their work. Throughout our organization, we strive for exemplary standards of organizational performance and teamwork."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c3"
                                        prefixClass="number-area pink-bg"
                                        prefix="4"
                                        titleClass="title"
                                        Title="Commitment"
                                        descClass="number-txt"
                                        Description="We always put the work first. Based on our core values, we deliver exceptional solutions. The highest quality development services are our goal. Our commitment to the work reflects our passion and work ethic."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mission;