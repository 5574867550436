import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-80 pb-50">
           <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our Laravel Development Services"
                        descClass="desc mb-25"
                        description="Hire a dedicated Laravel developer from us to build secure, scalable, and feature-rich websites and applications. We provide you end-to-end Laravel development services. We follow MVC pattern architecture and build modern business solutions."
                        effectClass="heading-line"
                />
                <div className="row align-items-center">
                <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Laravel Web Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Laravel Extension Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Laravel Customization & Integration"
                                 />
                            </div>
                            </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div> 
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Laravel Support and Maintenance"
                                />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Laravel Ecommerce Development"
                                />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Laravel Migration and Upgrades"
                                 />
                            </div>
                            </div>
                    </div>   
                    
                </div>
            </div>
        </div>
    );
}

export default OurServices;