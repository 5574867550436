import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/healthcare-about.webp';
import valueBg from'../../assets/img/banner/value-bg.png';


const AboutSlider = () => {

    return (
        <React.Fragment>
        <div className="style3 pt-50 pb-50" style={{
                backgroundImage: 'url('+valueBg+')',
                backgroundSize: "cover",
                backgroundPosition:"left bottom"
            }}>
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part pt-50">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-15"
                                        subtitleClass="sub-text style-bg"
                                        subtitle="Our Top Features "
                                        titleClass="title pb-0"
                                        title="Our Medical & Healthcare App Solutions"
                                        descClass="desc"
                                        description="Using our world-class healthcare mobile app development services, we have helped our clients increase their sales on multiple projects. Our custom healthcare app development solutions are also tailored so that they enhance the effectiveness & efficiency of medical practitioners, device manufacturers, care providers, etc."
                                    />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Diagnosis</li>
                                        <li className="">Doctor-On-Demand</li>
                                        <li className="">Health Monitoring</li>
                                        <li className="">Scheduling & Reminders</li>
                                        <li className="">Real-Time Chats</li>
                                        
                                    </ul>
                                </div>
                                <div className='col-lg-7 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Patient Health Education</li>
                                        <li className="">Cloud-Enabled</li>
                                        <li className="">Patient Monitoring</li>
                                        <li className="">Clinical Assistance</li>
                                        <li className="">Healthcare Reference & Database</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider