import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.png';
import mainIcon29 from '../../assets/img/service/style2/main-img/29.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-80 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Services"
                    titleClass="title testi-title"
                    title="We Are A Leading Provider Of Social Media App Development?"
                    descClass="desc"
                    description="As one of the leading Social Networking App Development Companies, we deliver cost-effective solutions to our clients. Keeping our clients happy and satisfied is our top priority. You can be assured that you will get timely delivery of the apps. Technical expertise makes your social networking app stand out from the crowd in the app stores."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon29}
                            hoverIcon={mainIcon29}
                            Title="Skilled Developers"
                            Text="We have a team of skilled and expert social app developers to work for you."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Cost-Effective Solutions"
                           Text="We are known for providing low-cost app development solutions."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Timely Delivery"
                            Text="Our team delivers the apps within the deadlines set for the projects."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Confidentiality"
                           Text="We maintain complete confidentiality of the clients’ projects by signing NDA."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Methodology"
                            Text="We follow agile methodology for the projects to ensure timely delivery."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Complete client satisfaction"
                            Text="Based on your needs, we provide user-friendly, scalable, secure, and new business solutions."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;