import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon99 from '../../assets/img/service/style2/main-img/99.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon98 from '../../assets/img/service/style2/main-img/98.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-80 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title"
                    title="Why Hire NodeJS Programmer from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Delivering complete solution"
                            Text="End-to-end NodeJS development services are providing by Metaqualt."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Tech Expertise"
                            Text="We offer you javascript development expertise; we have certified java developers for hire."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Methodology "
                           Text="We use Agile development methodology for the speedy development of web applications."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Detailed Consultation"
                            Text="To keep the development work on point, we undergo in-depth consultation with you to understand the need of the business."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon98}
                            hoverIcon={mainIcon98}
                            Title="Speedy Communication"
                            Text="We use modern communication tools such as Slack, Jira, Skype, Google Hangout for improved communication."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon99}
                            hoverIcon={mainIcon99}
                            Title="Simple & Flexible Contract"
                           Text="You can scale the team anytime during the development process, we have a very flexible hiring contract."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;