import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/phpDevelopment/SingleServiceSix';

// Service Image Icons
import serviceImg14 from '../../assets/img/service/style9/ss5.png';
import serviceImg13 from '../../assets/img/service/style9/ss6.png';
import serviceImg11 from '../../assets/img/service/style9/ss7.png';
import serviceImg12 from '../../assets/img/service/style9/ss8.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-50 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="Fintech Industry"
                    titleClass="title new-title"
                    title="We Offer Best-of-Class Solutions For Fintech App Development in Financial Services"
                    descClass="desc"
                    description="With our fintech app development services, FinTech startups & firms are able to effectively meet regulatory standards and industry benchmarks. One of the most advantageous aspects of our Tailor-Made fintech mobile app development services is that it keeps you up to date on the latest financial trends. Whenever you need us, we're here to help. So you can stay assured once you entrust us with your project requirements. As a result of our high-end, feature-rich, secure & robust apps, the financial ecosystem is effectively revolutionized. Through the years, we have successfully met the needs of a wide range of businesses, including fintech start-ups, traditional non-financial & financial firms. We offer cutting-edge insight, interactive features, superior customer experience, and high engagement wherever & whenever people need it, with our cloud-based digital mobility software solutions."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Mobile Payments (mPOS)"
                            
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg13}
                            Title="Personal FinTech Software"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg11}
                            Title="Banking Mobile Applications" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg12}
                            Title="Crowdfunding Portal Development"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

