import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from '../php-development/Technologies';
import SingleServiceFour from '../../components/Codeigniter/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import WhyChoose from '../php-development/whychooseus';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import bannerImg from '../../assets/img/banner/sf-development.webp';
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon4 from '../../assets/img/service/style2/main-img/4.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import imageServices1 from '../../assets/img/service/icon-1.webp'; 
import imageServices2 from '../../assets/img/service/icon-2.webp'; 
import imageServices3 from '../../assets/img/service/icon-3.webp'; 
import imageServices4 from '../../assets/img/service/icon-4.webp'; 
import imageServices5 from '../../assets/img/service/icon-5.webp'; 
import imageServices6 from '../../assets/img/service/icon-6.webp'; 
import imageServices7 from '../../assets/img/service/icon-7.webp'; 
import imageServices8 from '../../assets/img/service/icon-8.webp'; 
import imageServices9 from '../../assets/img/service/icon-9.webp'; 
import imageServices10 from '../../assets/img/service/icon-10.webp'; 


const ciDevelopmentMain = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Our Expertise"
                                titleClass="title pb-25"
                                title="Developing solutions with CodeIgniter that works, wins, and wows"
                                descClass="desc pb-5"
                                description="This PHP framework uses an MVC architecture for developing web applications quickly. A CodeIgniter web application can connect to databases as well as send emails, upload files, manage sessions, and many other tasks. With CodeIgniter, you don't have to spend time writing the same code over and over again. Model–view–controller (MVC) is at the core of CodeIgniter. Models and views are optional, while controller classes are necessary. HMVC (hierarchical model view controller) allows users to set up modular groupings of Controller, Models, and Views within a subdirectory structure."  
                                
                            />
                        </div>
                        
                    </div>
                    <div className="row pt-50">

                        <div className="col-lg-12 col-md-12 pt-25 text-center">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                titleClass="title pb-25 w-80 fz-36"
                                title="We Place Your Brand First When It Comes To CodeIgniter Development"
                                descClass="desc pb-5"
                                description="CodeIgniter is a framework that allows developers to build PHP-based websites. It allows users to develop projects much more quickly than if they had to write code from scratch. The library provides a rich set of functions for common tasks & a simple interface to access them. A web application developed with CodeIgniter framework allows users to focus creatively on the project. We provide CodeIgniter development services to help you build dynamic web platforms with full features. The PHP developers at we provide innovative, orchestrated, and transformative solutions that drive results."                           
                             />
                        </div>

                    </div>

                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
            
            {/* rs-servicess-area-start */}
            <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Codeigniter Development Services"
                        titleClass="title"
                        title="Next-Gen Codeigniter Development Beside Professional Excellence"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={imageServices4}
                            Title="Custom E-Commerce Development"
                           
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices6}
                            Title="PHP CodeIgniter Web App Development" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices2}
                            Title="Develop Web Portals with PHP CodeIgniter" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices8}
                            Title="CodeIgniter Social Networking Solutions" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices3}
                            Title="Easy configuration and simplicity" 
                           
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices9}
                            Title="CodeIgniter Maintenance & Support" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices6}
                            Title="Back-end for Mobile Apps Development"
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices5}
                            Title="Integration with SugarCRM Development"
                            
                        />
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why Choose Us for Development?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Year-long Expertise"
                            Text="Our Laravel development expertise and experience serve a variety of niches, sizes, and types of businesses."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Customer-Centric" 
                            Text="After the development of our clients' websites has been completed, we continue to support the sites consistently & proactively." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            Title="Futuristic Development" 
                            Text="The Laravel apps we build follow a futuristic development approach to ensure that they stand the test of time." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Dedicated Team" 
                            Text="Our Laravel app developers and designers are thorough experts in all the features, skills, and tools of the platform." 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-end */}
            
            {/* rs-servicess-area-start */}
            <WhyChoose />
            {/* rs-services-area-end */}

            {/* rs-servicess-area-start */}
           <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire The Dedicated Codeigniter Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}
                      
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default ciDevelopmentMain;