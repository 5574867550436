import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/phpDevelopment/SingleServiceSix';

// Service Image Icons

import serviceImg57 from '../../assets/img/service/19.png';
import serviceImg58 from '../../assets/img/service/20.png';
import serviceImg59 from '../../assets/img/service/21.png';
import serviceImg60 from '../../assets/img/service/22.png';
import serviceImg64 from '../../assets/img/service/23.png';
import serviceImg61 from '../../assets/img/service/24.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-50 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="Social Networking"
                    titleClass="title new-title"
                    title="Our Social Networking App Solutions."
                    descClass="desc"
                    description="We offer the best social media app development services that meet your exact requirements and business goals by utilizing the latest tools and technologies. Our esteemed clients have benefited from our mobile app development services through a number of projects. Our social media application development solutions are scalable and robust."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg57}
                            Title="Messaging App" 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg58}
                            Title="Social Networking Portal"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg60}
                            Title="Mobile Dating Apps" 
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg64}
                            Title="Social News Sharing App"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg59}
                            Title="Image/video Sharing App"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg61}
                            Title="Community App Development"
                        />
                    </div>
                   
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

