import React from 'react';
import { Link } from 'react-router-dom';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CaseStyleFive from '../../components/CaseStudy/CaseStyleFive';
import SectionTitle from '../../components/Common/SectionTitle';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import Brand from '../../components/Common/Brand';
// About Images
import aboutPart1 from '../../assets/img/about/abut-1.webp';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
// Team Images
import image1 from '../../assets/img/project/S1.jpg';
import image2 from '../../assets/img/project/S2.jpg';
import image3 from '../../assets/img/project/S4.jpg';
import image4 from '../../assets/img/project/S3.jpg';
import image5 from '../../assets/img/project/P11.jpg';
import image6 from '../../assets/img/project/P7.jpg';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';

const CaseStudtyStyleFive = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                activeMenu='/Portfolio'
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Our Portfolio"
                breadcrumbsImg={bannerbg}
                pageName="Our Portfolio"
                animatedFont="lined-Font dance2"
                animatedFontsize="Our Portfolio"
            />
            {/* Section Title Start */}
            <div id="rs-about" className="rs-about pt-150 pb-50">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={aboutPart1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Portfolio"
                                titleClass="title pb-10"
                                title="We Design Products With Creativity, Made With Passion"
                                descClass="desc"
                                description="We have handled a variety of web & mobile app development projects successfully. Our team's skills and expertise can be seen in these projects. Partnering with smart leaders accelerates growth, profitability, and progress. Our ideas have been applied to some of the most innovative companies in the world. With a commitment to quality and meeting deadlines, we have experience across industries, organization sizes, and geographies. With the help of our innovative solutions, we strive to take our clients' businesses to the next level. We design and develop software, websites, and apps for enterprises, medium-sized businesses, and startups to help them raise millions and reach millions of people."
                                
                            />
                            {/* Section Title END    */}
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
             {/* Section Title END    */}

            {/* CaseStudy Start */}
            <div className="rs-project style7 pt-50 pb-80">
                <div className="container">
                    {/* Section Title Start */}
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Portfolio"
                        titleClass="title pb-10"
                        title="Our Works"
                        descClass="desc"
                        description="In todays cutting era, failure to implement an application or an unpredicted downtime can outcome in losing your consumers trust."
                        effectClass="heading-line"
                     />
                    {/* Section Title END    */}
                    <div className="row mt-60">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleFive
                                caseClass="project-item"
                                caseImage={image1}
                                caseTitle="E-Commerce App"
                                caseCategory="Solution"
                                solutionURL="./ecommerce-solutions"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleFive
                                caseClass="project-item"
                                caseImage={image2}
                                caseTitle="Business App"
                                caseCategory="Solution"
                                solutionURL="./business-app-solution"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleFive
                                caseClass="project-item"
                                caseImage={image3}
                                caseTitle="Social Media App"
                                caseCategory="Solution"
                                solutionURL="./social-media-app"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <CaseStyleFive
                                caseClass="project-item"
                                caseImage={image4}
                                caseTitle="Real Estate App"
                                caseCategory="Solution"
                                solutionURL="./real-estate-app-solution"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <CaseStyleFive
                                caseClass="project-item"
                                caseImage={image5}
                                caseTitle="Travel App"
                                caseCategory="Solution"
                                solutionURL="./travel-app-solutions"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <CaseStyleFive
                                caseClass="project-item"
                                caseImage={image6}
                                caseTitle="Fitness App"
                                caseCategory="Solution"
                                solutionURL="./fitness-wellness-app-solutions"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* CaseStudy End */}

            {/* brand-area-start */}
			<Brand />
			{/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    )
}

export default CaseStudtyStyleFive