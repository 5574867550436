import React from 'react';
import WeServes from './it-services'
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Process/SingleProcess';
import Brand from '../../components/Common/Brand';
import WhyChoose from './whychooseus';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import Project from './ProjectSection';
import Technology from '../../components/Technology';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

import aboutPart1 from '../../assets/img/about/abut-1.webp';

const SolutionMainPage = () => {

    return (
        <React.Fragment>
           
            {/* <!-- banner section end --> */}
            <div id="rs-about" className="rs-about pt-150 pb-40 md-pt-80 md-pb-80 sm-pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center pt-40 pb-15">
                                 <img className="main" src={aboutPart1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 pl-15">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 pt-10 mb-0"
                                subtitleClass="sub-text style-bg"
                                subtitle="About Us"
                                titleClass="title pb-25"
                                title="Our Goal is to Transform Your Ideas Into An Energy Source For Your Company!"
                                descClass="desc mb-25"
                                description="The mission of our company is to assist our clients in automating their operations, engaging their consumers & making a difference in the world. Our mobile app solutions help you grow your business & provide a better customer experience. We understand your business requirements. We create e-commerce, marketing, advertisement, and people-to-people marketing solutions that are tailored to our clients' needs thanks to our end-to-end knowledge of these industries. "
                                secondDescClass="mb-10 pb-16"
                                secondDescription="Our clients benefit from our world-class and most innovative web and mobile applications in a range of trending and on-demand solutions."
                            />                           
                        </div>
                    </div>
                </div>
            </div>
            {/* about-area-start */} 

            {/* counter area start */}
            <CounterHomeFive />
            {/* counter area end */}

            {/* about-area-start */}
			<WeServes />
			{/* about-area-end */}

            {/* Project-start */}
			<Project />
			{/* Project-end */}                
            
            {/* why choose us area start */}
            <WhyChoose />
            {/* why choose us area start */}

            {/* Technology-area-start */}
			<Technology />
			{/* Technology-area-end */}

            {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}           

            {/* brand-area-start */}
			<Brand />
			{/* brand-area-end */}

        </React.Fragment>
    )
}

export default SolutionMainPage;