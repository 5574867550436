import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/about/andriod-app.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from '../mobile-app-development/Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import WhyChoose from '../php-development/whychooseus';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// tech Icons
import techImg10 from '../../assets/img/technology/style2/10.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg6 from '../../assets/img/technology/style2/6.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg17 from '../../assets/img/technology/style2/17.svg';

// Service Icons
import imgMain50 from '../../assets/img/service/style2/main-img/50.png';
import imgMain49 from '../../assets/img/service/style2/main-img/49.png';
import imgMain47 from '../../assets/img/service/style2/main-img/47.png';
import imgMain48 from '../../assets/img/service/style2/main-img/48.png';
import imgMain30 from '../../assets/img/service/style2/main-img/30.png';

// Service Image Icons
import serviceIcon34 from '../../assets/img/service/style2/main-img/34.png';
import serviceIcon4 from '../../assets/img/service/style2/main-img/4.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const IosAppMainPageContent = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-80 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Make Yours IOS App Differently"
                                titleClass="title pb-25"
                                title="Your Business Needs An iOS App Development Solution"
                                descClass="desc pb-5"
                                description="We build iOS apps that are scalable and customized to meet the needs of businesses. Our dedicated UX/UI team delivers flawlessly designed apps that use the latest technologies to give you an edge in the market. The expertise we have allows us to build professional iOS applications for all screen sizes and provide end-to-end solutions throughout the entire lifecycle of the application, from ideation to planning, monitoring to testing, and launching."
                                secondDescClass="desc pb-16"
                                secondDescription="With our team of skilled iOS app developers, we possess extensive knowledge of iOS SDK, frameworks & tools required in iOS app development. From designing the app to its launch, we handle everything. Besides that, we also provide long-term maintenance & support for iOS applications."
                            />
                        </div>
                        
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

           {/* rs-technology-area-start */}
             <div className="rs-technology">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-20"
                        title="Robust Tech Stack to Power Your Android Development Software"
                        effectClass="heading-line"
                    />
                    <div className="row">            
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                    
                                        <div className="logo-img">
                                            <img src={techImg17} alt="" />
                                        </div>
                                   
                                </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                    
                                        <div className="logo-img">
                                            <img src={techImg3} alt="" />
                                        </div>
                                   
                                </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                
                                    <div class="logo-img">
                                        <img src={techImg4} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                
                                    <div class="logo-img">
                                        <img src={techImg8} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                        
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item pt-5 pb-5">
                                
                                    <div class="logo-img">
                                        <img src={techImg10} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                
                                    <div class="logo-img">
                                        <img src={techImg6} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-technology-area-start */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our iPhone Excellence"
                        titleClass="title pb-10"
                        title="An iPhone App Development Company That Delivers Polished Apps"
                        descClass="desc"
                        description="The iOS apps we create fit your business requirements perfectly, thanks to our years of experience creating mobile apps."
                        effectClass="heading-line"
                    />                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain49}
                                    HoverImg={imgMain49}
                                    Title="iPhone App Development"
                                    Text="Become an iPad and iPhone app developer with our experienced team of iOS app developers. Taking businesses to the next level requires customised solutions."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6  mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain50}
                                    HoverImg={imgMain50}
                                    Title="UI & UX Design Services"
                                    Text="A member of our user experience (UX) design team has expertise in creating visually appealing prototypes, sophisticated user interfaces, and a customized user experience."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6  mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain47}
                                    HoverImg={imgMain47}
                                    Title="Quality Assurance & Testing"
                                    Text="An experienced in-house testing team is available to ensure optimum quality using a multitude of manual and automation testing tools & industry benchmark practices."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain48}
                                    HoverImg={imgMain48}
                                    Title="iPhone Apps Support & Maintenance"
                                    Text="With our 3-month free support, you can rest assured that any bug or crash (if any) will be dealt with on a priority basis. This support is even extendable indefinitely for our customers."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain30}
                                    HoverImg={imgMain30}
                                    Title="App Porting & Migration"
                                    Text="Our bespoke iPhone mobile application Porting & Migration service render to keep you in balance with the altering mobile ecosystem." 
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Professional Excellence Combined with Next-Gen iOS App Development"
                                    effectClass="heading-line"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}       

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why Choose Us for Development?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon34}
                                Title="Flexible Hiring Models"
                                Text="You can choose from a variety of flexible hiring models according to your budget & requirements when it comes to iOS app development."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon4}
                                Title="Diverse Technologies" 
                                Text="iOS app developers are available for any niche app project requiring coding skills in Objective-C, Swift, JSON, xCode, Cocoa Framework, Cocos2d, etc." 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon3}
                                Title="Best Talent" 
                                Text="We boast a team of experienced iOS app developers skilled in all the advanced SDKs from Apple, as well as IoT platforms including Wearables, iBeacon, NFC, etc." 
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-services-area-start */}
            <WhyChoose />
            {/* rs-services-area-END */}

            {/* rs-servicess-area-start */}
            <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated iOS App Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default IosAppMainPageContent;