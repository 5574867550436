import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/phpDevelopment/SingleServiceSix';

// Service Image Icons
import serviceImg81 from '../../assets/img/service/style2/main-img/91.png';
import serviceImg82 from '../../assets/img/service/style2/main-img/92.png';
import serviceImg83 from '../../assets/img/service/style2/main-img/93.png';
import serviceImg84 from '../../assets/img/service/style2/main-img/94.png';
import serviceImg85 from '../../assets/img/service/style2/main-img/95.png';
import serviceImg86 from '../../assets/img/service/style2/main-img/96.png';
import serviceImg87 from '../../assets/img/service/style2/main-img/97.png';
import serviceImg88 from '../../assets/img/service/style2/main-img/91.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-60 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="Real Estate Industry"
                    titleClass="title new-title pb-0"
                    title="Real Estate Businesses Benefit From Our Mobile App Solutions."
                    descClass="desc"
                    description="Develop custom real estate apps to gain an edge over your competitors. By utilizing cutting-edge tools and technology, you can get real estate mobile applications that are future-proof. Boost your real estate business with our innovative Real Estate Mobile App Development Services. With custom real estate applications, you can monitor properties, purchase, sell, and give the right property to the right buyer or seller."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg82}
                            Title="Real Estate Marketplace" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg81}
                            Title="Real Estate Web App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg83}
                            Title="Home Search Solution"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg84}
                            Title="Property Brokerage App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg85}
                            Title="B2B & B2C Real Estate App" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg87}
                            Title="Lead Management Application"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg85}
                            Title="Real Estate Website Development"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg86}
                            Title="Online Property Booking App"
                        />
                    </div>
                   
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

