import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

import aboutImg1 from '../../assets/img/about/social-media.webp';
import valueBg from'../../assets/img/banner/value-bg.png';

const AboutSlider = () => {
    return (
        <React.Fragment>
           <div className="style3 pt-50 pb-50" style={{
                backgroundImage: 'url('+valueBg+')',
                backgroundSize: "cover",
                backgroundPosition:"left bottom"
            }}>
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title ">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10"
                                    title="Social Media Application "
                                    descClass="desc "
                                    description="We create social media applications that are unique and interactive by combining knowledge and experience. Having a clear understanding of your target audience helps us to design an app that matches your business needs. Create productive solutions with our team of social app developers."
                                />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Maps Integration</li>
                                        <li className="">Push notifications</li>
                                        <li className="">Real-time chat </li>
                                        <li className="">Story sharing </li>
                                        <li className="">Chatbots integration</li> 
                                        <li className="">Geo-tagging</li>
                                        <li className="">Video uploading</li>
                                        <li className="">AI &amp; AR Integration</li>
                                        <li className="">Marketplace Option </li>
                                        
                                        <li className="">Blockchain Integration</li>
                                    </ul>
                                </div>
                                <div className='col-lg-8 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Media Sharing &amp; Upload</li>
                                        <li className="">Media upload &amp; sharing  </li>
                                        <li className="">Social media API integration </li>
                                        <li className="">Secure login and privacy controls</li>
                                        <li className="">Content sharing, Audio/Video chat, recommendation</li>
                                        <li className="">Advance setting includes private, personal details, preferences & more</li>
                                        <li className="">Simple and easy to connect & chat with groups</li>
                                        <li className="">Attractive profile features including background image, color, font style, wallpaper, level, etc.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider