import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import JavaScripts from './javascripts';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';


const JSDevelopment = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="JavaScripts Development"
                    titleClass="page-title"
                    pageName="JavaScripts Development "
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="JavaScripts"
                />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                <JavaScripts />
                {/* ServiceOneMain */}
            </div>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default JSDevelopment;