import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';
import valueBg from'../../assets/img/banner/value-bg.png';
import aboutImg1 from '../../assets/img/about/business-solution.webp';

const TechSlider = () => {
    return (
        <React.Fragment>
            <div className="style3 pt-80 pb-80 md-pt-80 md-pb-80" style={{
            backgroundImage: 'url('+valueBg+')',
            backgroundSize: "cover",
            backgroundPosition:"left bottom"
        }}>
        <div className="container relative">
                <div className="sec-left">
                    <h2 className="title ">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-12'>
                            <div className="image-part pt-60 md-mb-50">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 "
                                    title="Business Application"
                                    descClass="desc "
                                    description="As an enterprise application development company, MetaQuotes possesses a lot of expertise and knowledge that let us develop outstanding, complex, and customized apps for businesses. We offer best B2C and B2B portal development services to help them compete in this highly competitive market. Our company first focuses on understanding the target audience of your project as soon as you assign it to us. Having this information helps us to build tailored apps for your business."
                                />
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Rapid Development & Deployment</li>
                                        <li className="">Web & Mobile Applications</li>
                                        <li className="">Endless Integration Capabilities</li>
                                        <li className="">Built-In Collaboration Tools</li>
                                        
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Multi-Cloud Native by Default</li>
                                        <li className="">Version Control & App Quality</li>
                                        <li className="">Marketplace & Community Support</li>
                                        <li className=''>Multiple Payment Options Support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default TechSlider;