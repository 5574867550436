import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcessTwo from '../../components/Process/SingleProcessTwo';

import shapeImg from'../../assets/img/process/circle.png';
import valueBg from'../../assets/img/banner/value-bg.png';

const Mission = () => {

    return (
        <div className="" style={{
            backgroundImage: 'url('+valueBg+')',
            backgroundSize: "cover",
            backgroundPosition:"left bottom"
          }}>
            <div className="rs-process modify1 pt-80 pb-80">
                <div className="shape-animation hidden-sm">
                    <div className="shape-process">
                        <img className="dance2" src={shapeImg} alt="images" />
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 md-text-center pr-40 md-pr-14 md-pb-80">
                            <SectionTitle
                                sectionClass="sec-title2 mb-30 md-pl-30 md-pr-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Why"
                                titleClass="title mb-5"
                                title="Why Web Development?"
                                descClass="desc pb-2"
                                description="A web developer creates dynamic web applications. In recent years, web development has become increasingly popular. Our main concern is to create a site with less coding without compromising its functionality. You would barely get any crawling impact that prevents you from returning to this entry again from the key idea of an end-user."
                            />
                            
                        </div>
                        <div className="col-lg-8 sm-pl-40 sm-pr-20">
                            <div className="row">
                                <div className="col-md-6 mb-70 ">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c1"
                                        prefixClass="number-area"
                                        prefix="1"
                                        titleClass="title"
                                        Title="Website Security"
                                        descClass="number-txt"
                                        Description="Keeping our customers' sites secure is our top priority. A viable and understanding site is ensured by incorporating all the possible security layers."
                                    />
                                </div>
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c2"
                                        prefixClass="number-area green-bg"
                                        prefix="2"
                                        titleClass="title"
                                        Title="Smart Application Structure"
                                        descClass="number-txt"
                                        Description="As the project progresses, we follow a systematic methodology based on its unpredictability, specialized requirements, and other factors."
                                    />
                                </div>
                                <div className="col-md-6 sm-mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c4"
                                        prefixClass="number-area plum-bg"
                                        prefix="3"
                                        titleClass="title"
                                        Title="Long haul Cost Saving"
                                        descClass="number-txt"
                                        Description="As we compose code, we allow developers to improve features or introduce new ones without affecting existing code significantly."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c3"
                                        prefixClass="number-area pink-bg"
                                        prefix="4"
                                        titleClass="title"
                                        Title="Extraordinary Support"
                                        descClass="number-txt"
                                        Description="Providing promising support includes solution, adjustment, development, as well as upkeep of the delivered software."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mission;