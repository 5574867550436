import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import serviceIcons1 from '../../assets/img/service/style1/s1.png';
import serviceIcons2 from '../../assets/img/service/style1/s2.png';
import serviceIcons3 from '../../assets/img/service/style1/s3.png';
import serviceIcons4 from '../../assets/img/service/style1/s4.png';
const Service = () => {

    return (
        <div id="rs-service" className="rs-services main-home style6 pt-40 pb-40 md-pt-50 md-pb-50">
            <div className="container">
                {/* <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text style-bg"
                    subtitle="Solution"
                    titleClass="title"
                    title="Enabling Smart Digital Transformation Expertise to keep you Ahead"
                    effectClass="heading-line"
                /> */}
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1"
                            serviceImage={serviceIcons1}
                            Title="Creative Ideas"
                            Text="Duis vel nibh at velit scelerisque suscipit."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1 active"
                            serviceImage={serviceIcons2}
                             Title="Innovative Tools"
                            Text="Duis vel nibh at velit scelerisque suscipit."
                       />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1"
                            serviceImage={serviceIcons3}
                            Title="Better Performance"
                            Text="Duis vel nibh at velit scelerisque suscipit."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1"
                            serviceImage={serviceIcons4} 
                            Title="Goal Achievement"
                            Text="Duis vel nibh at velit scelerisque suscipit."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;