import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/realetate.webp';
import valueBg from'../../assets/img/banner/value-bg.png';

const AboutSlider = () => {

    return (
        <React.Fragment>
            <div className="style3 pt-50 pb-80 md-pt-80 md-pb-80" style={{
                    backgroundImage: 'url('+valueBg+')',
                    backgroundSize: "cover",
                    backgroundPosition:"left bottom"
                }}>
                <div className="container relative">
                    
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 "
                                    title="Our Real Estate Application Solutions"
                                    descClass="desc "
                                    description="To assist you in achieving your business goals, our real estate app developers understand your exact business requirements. Team members have extensive experience and are talented enough to produce top-notch mobile applications for real estate. Real estate apps have the following unique features:"
                                />
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Interest Rate Calculators</li>
                                        <li className="">AI-enabled Chatbots</li>
                                        <li className="">Users, Agents, Sellers Profile</li>
                                        <li className="">Tracking License and Status</li>
                                        <li className="">Multilingual & Multi-currency</li>
                                        <li className="">Videos and Virtual Tours</li>
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Property Listings</li>
                                        <li className="">Push Notifications</li>
                                        <li className="">Calendars</li>
                                        <li className="">Currency Converters</li>
                                        <li className="">Property Estimates</li>
                                        <li className="">GPS Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider