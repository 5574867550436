import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-80 pb-50">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our PHP Web Development Services"
                        descClass="desc mb-25"
                        description="Hire a PHP developer for your project based on your unique needs. We provide you innovative solutions that help you make a mark in the industry domain while also meeting your business goals. Here are our PHP web development services."
                         effectClass="heading-line"
                />
                <div className="row align-items-center">

                    
                            <div className="col-md-4 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Web Apps Development"
                                 />
                            </div>
                            <div className="col-md-4 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Ecommerce Development"
                                 />
                            </div>
                            <div className="col-md-4 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Enterprise App Development"
                                />
                            </div>
                           
                      
                   
                            <div className="col-md-4 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Web Services and API Development"
                                />
                            </div>
                            <div className="col-md-4 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Upgrade / Modernize PHP Applications"
                                 />
                            </div>
                            <div className="col-md-4 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Cloud-based Application Development"
                                 />
                            </div>
                        
                            <div className="col-md-1 mb-40"></div>
                            <div className="col-md-5 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="7."
                                    Title="Migration to PHP"
                                />
                            </div>
                            <div className="col-md-5 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="8."
                                    Title="Support & Maintenance"
                                />
                            </div>
                            <div className="col-md-1 mb-40"></div>
                        
                </div>
            </div>
        </div>
    );
}

export default OurServices;