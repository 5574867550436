import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/sf-development.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from '../php-development/Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import WhyChoose from '../php-development/whychooseus';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain16 from '../../assets/img/service/style2/main-img/16.png';
import imgMain21 from '../../assets/img/service/style2/main-img/21.png';
import imgMain103 from '../../assets/img/service/style2/main-img/103.png';
import imgMain22 from '../../assets/img/service/style2/main-img/22.png';
import imgMain23 from '../../assets/img/service/style2/main-img/23.png';


// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon4 from '../../assets/img/service/style2/main-img/4.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

const phpDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Our Laravel Expertise"
                                titleClass="title pb-25"
                                title="Our Laravel Development Services Are Powered By Agile Methodology"
                                descClass="desc pb-5"
                                description="Laravel is an open-source and free framework for PHP-based web applications. MVP architecture is the basis for its development. We provide you with exceptional developers who are friendly and cooperative and will provide you with great services that will benefit your business. We provide robust Laravel solutions to your business based on our experience with the framework. We can help you develop your website or application using Laravel framework."
                             />
                        </div>
                        
                    </div>
                    
                    <div className="row pt-50">

                        <div className="col-lg-12 col-md-12 pt-25 text-center">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                titleClass="title pb-25 w-80 fz-36"
                                title="Providing Professional Excellence Alongside Next-Generation Laravel Development"
                                descClass="desc pb-5"
                                description="As a Laravel development company, we use this open source framework for custom web development, Enterprise solution development and Restful web services. To provide robust, real-time solutions for all industries, our Laravel developers have adopted all skills of the framework. The feature-rich web applications we develop with Laravel break the norms. In addition to developing a great design, it's also important to integrate it with backend functions and ensure its performance. It is done with excellence by our adroit Laravel developers. This is why we are considered the best Laravel development company."                            />
                        </div>

                    </div>


                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="Our Laravel Development Service Can Help You Grow Your Business"
                        descClass="desc"
                        description="Services for Laravel development offer a variety of benefits. Codes are simple & bundles are plentiful. With its migration options, developers can create modern, attractive websites. It's easy to recognize a website that uses these tools. You can create it with our help!."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-40">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain16}
                                    HoverImg={imgMain16}
                                    Title="Custom Laravel Development"
                                    Text="Using a team of talented Laravel developers, we ensure that our solutions deliver excellent performance, industry-specific features & design."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain21}
                                    HoverImg={imgMain21}
                                    Title="Laravel Enterprise Apps"
                                    Text="We provide Laravel enterprise app solutions tailored to address the unique needs of your business."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain103}
                                    HoverImg={imgMain103}
                                    Title="Laravel e-Commerce Apps"
                                    Text="We use Laravel for e-commerce ventures to create robust and scalable e-commerce apps with excellent performance."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain22}
                                    HoverImg={imgMain22}
                                    Title="Maintenance & Support"
                                    Text="Keeping our Laravel apps updated with frequent value additions is one of our key post-development services."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain23}
                                    HoverImg={imgMain23}
                                    Title="Laravel Data Migration"
                                    Text="For websites upgrading to the latest versions, we provide comprehensive Laravel data migration solutions."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="As a Trusted Laravel Developer, Our Team Provides Adroit Services."
                                    effectClass="heading-line"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why Choose Us for Development?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Year-long Expertise"
                                Text="Our Laravel development expertise and experience serve a variety of niches, sizes, and types of businesses."
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="Customer-Centric" 
                                Text="After the development of our clients' websites has been completed, we continue to support the sites consistently & proactively." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon4}
                                Title="Futuristic Development" 
                                Text="The Laravel apps we build follow a futuristic development approach to ensure that they stand the test of time." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon3}
                                Title="Dedicated Team" 
                                Text="Our Laravel app developers and designers are thorough experts in all the features, skills, and tools of the platform." 
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-servicess-area-start */}
             <WhyChoose />
            {/* rs-services-area-end */}

            {/* rs-servicess-area-start */}
           <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 


            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire The Dedicated Laravel Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default phpDevelopment;