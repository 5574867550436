import React from 'react';
import SectionTitle from '../Common/SectionTitle';

// WhyChooseUs Images
import iconOne from '../../assets/img/choose/icons/style2/1.png';
import iconTwo from '../../assets/img/choose/icons/style2/2.png';
import iconThree from '../../assets/img/choose/icons/style2/3.png';



const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
            <SectionTitle
                sectionClass="sec-title mb-44"
                subtitleClass="sub-text new-text white-color"
                subtitle="Let's Talk"
                titleClass="title white-color"
                title="Speak With Expert"
                effectClass="heading-line"
            />
                        <div className="services-wrap mb-30">
                            <div className="services-icon">
                                <img
                                    src={iconOne}
                                    alt="Choose Icon"
                                />
                            </div>
                            <div className="services-text">
                                <h3 className="title white-color"><a href="#">Email</a></h3>
                                <p className="services-txt white-color">info@metaqualt.com</p>
                            </div>
                        </div>
                        <div className="services-wrap mb-30">
                            <div className="services-icon">
                                <img
                                    src={iconTwo}
                                    alt="Choose Icon"
                                />
                            </div>
                            <div className="services-text">
                                <h3 className="title white-color"><a href="#">Call Us</a></h3>
                                <p className="services-txt white-color">(+91) 999–5554</p>
                            </div>
                        </div>
                        <div className="services-wrap">
                            <div className="services-icon">
                                <img
                                    src={iconThree}
                                    alt="Choose Icon"
                                />
                            </div>
                            <div className="services-text">
                                <h3 className="title white-color"><a href="#">Office Address</a></h3>
                                <p className="services-txt white-color">374 FA Tower, William S Blvd <br></br>2721, IL, USA</p>
                            </div>
                        </div>
        </div>
    );

}

export default ContactInfo;