import { Link } from 'react-router-dom';

const SingleServiceSix = (props) => {
	return(
        <div className={props.itemClass}>
            <div className="services-img">
                
                    <img 
                        className="main-img"
                        src={props.serviceImg} 
                        alt={props.Title}
                    />
                
            </div>
            <div className="services-content">
                <div className="services-text">
                    <div className="services-title">
                        <h3 className="title">
                            
                            {props.Title}
                            
                        </h3>
                    </div>
                    <p className="services-txt">
                    {props.Text}
                    </p>
                </div>
               
            </div>
        </div>
	)
}

export default SingleServiceSix