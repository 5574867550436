import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../Common/SectionTitle';
import SingleProject from './SingleProject';


import projectImgP1 from '../../assets/img/project/P1.jpg';
// import projectImgP2 from '../../assets/img/project/P2.jpg';
import projectImgP3 from '../../assets/img/project/P3.jpg';
import projectImgP4 from '../../assets/img/project/P4.jpg';
import projectImgS3 from '../../assets/img/project/S3.jpg';
// import projectImgP6 from '../../assets/img/project/P6.jpg';
import projectImgP7 from '../../assets/img/project/P7.jpg';
import projectImgP11 from '../../assets/img/project/P11.jpg';
import projectImgS2 from '../../assets/img/project/S2.jpg';

const Project = () => {

    const options = {
        items: 3,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            992: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            1200: {
                items: 3,
                nav: true,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style1 pt-80 pb-50 md-pt-80 mb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44 md-mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Top Industries We Serve"
                        titleClass="title"
                        title="We Are Offering All Kinds of IT Solutions Services"
                        descClass="descr"
                        description="We offer Digital Transformation solutions for each commercial sector, with solutions that reinvent company operations and inspire creativity. By unlocking new value, growing trust, and handing over transparency all through their businesses."
                        effectClass="heading-line"
                        />
                    <OwlCarousel options={options} >
                        
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgP1} 
                            Title="FINTECH" 
                            projectURL="./fintech-app-solution"
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgP3} 
                            Title="E-COMMERCE" 
                            projectURL="./ecommerce-solutions"
                        />
                       
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgS2} 
                            Title="HEALTHCARE" 
                            projectURL="./healthcare-app-solution"
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgP11} 
                            Title="TRAVEL" 
                            projectURL="./travel-app-solutions"
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgS3} 
                            Title="REAL-ESTATE" 
                            projectURL="./real-estate-app-solution"
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgP4} 
                            Title="BUSINESS APP" 
                            projectURL="./business-app-solution"
                       />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgP7} 
                            Title="HEALTH & FITNESS" 
                            projectURL="./fitness-wellness-app-solutions"
                       />
                        
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Project