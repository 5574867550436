import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';

// Service Image Icons
import roundImg from '../../assets/img/banner/sf-development.webp';


import effectImg1 from '../../assets/img/about/effect-1.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about style3 pt-120 pb-50 md-pt-30">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 md-mb-30">
                    <div className="rs-animation-image md-pt-0">
                            <div className="pattern-img">
                                <img 
                                    src={roundImg} 
                                    alt="" 
                                /> 
                            </div>
                            {/* <div className="middle-img hidden-md">
                                <img 
                                    className="dance3" 
                                    src={aboutImg} 
                                    alt="" 
                                />
                            </div> */}
                            
                        </div>
                    </div>
                    <div className="col-lg-6 pl-20 md-pl-14">
                        <div className="contact-wrap">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Speeding up Innovations in a Real World"
                                titleClass="title pb-25"
                                title="We Design Products, Develop Platforms & Deliver Experiences Globally."
                                descClass="desc pb-27"
                                description="Over 25 years working in IT services developing software applications and mobile apps for clients all over the world."
                                secondDescClass="margin-0 pb-16"
                                secondDescription="We pursue our client's objectives with quality and fulfillment. We pick advancements/stages to such an extent that it suits our client's necessities and we can deliver things in ideal way. Our specialists are fit for working in a large portion of the innovations and platforms. We give Desktop/Mobile/Web/Small Business/Large Organizations arrangements and Our subjective investigation makes best solutions for our clients."
                            />
                            {/* Section Title End */}
                            <div className="btn-part">
                                <Link className="readon learn-more" to="./about">Learn-More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-image">
                    <img className="top dance" src={effectImg2} alt="" />
                    <img className="bottom dance" src={effectImg3} alt="" />
                </div>
            </div>
        </div>
    );
}

export default About;