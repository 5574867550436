import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
import mainIcon90 from '../../assets/img/service/style2/main-img/90.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services  style3 pt-80 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Solution"
                    titleClass="title testi-title"
                    title="Choosing Us for Travel App Development?"
                    descClass="desc"
                    description="Delivering exceptional solutions for our clients is what makes us experts at creating unique travel apps. Our travel and tourism mobile apps are built with the latest technologies. We offer feature-rich, tourism-specific software solutions as a top travel software development company. Our services include creating travel website portals, travel planning apps, ticket booking apps, and much more."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon2}
                            hoverIcon={mainIcon2}
                            Title="One-Stop Destination"
                            Text="Being a one-stop solution for mobile app development services, we develop future-ready apps."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Improve Your Brand Reputation"
                            Text="We aim to help you improve your brand recognition with top-quality travel app development services."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Hassle-Free Experience"
                            Text="You don’t have to worry about anything when you hire us for travel application development services."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon90}
                            hoverIcon={mainIcon90}
                            Title="Round-The-Clock Support"
                           Text="We provide round-the-clock support to our clients so that your app is up all the time."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Affordable"
                           Text="You can get affordable mobile app development solutions for travel businesses."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Quick Delivery"
                            Text="Our company is known for delivering mobile apps on time, and within your budget."
                        />
                    </div>
                </div>
            </div>
           
        </div>
    );
}

export default WhyChoose;