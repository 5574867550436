import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/main-home/web-coding.png';
import mainIcon2 from '../../assets/img/service/main-home/nocost.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon40 from '../../assets/img/service/main-home/soft-testing.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-50 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text  style-bg"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title"
                    title="Why Hire App & Website Developers from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Dedicated Web Developers"
                           Text="Hire dedicated web developers that put their undivided attention into your project and give you the best results."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon2}
                            hoverIcon={mainIcon2}
                            Title="No Unnecessary Charges"
                           Text="With a monthly-rolling contract, you only pay for measurable work done by the best web developers."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="03"
                            mainIcon={mainIcon40}
                            hoverIcon={mainIcon40}
                            Title="Get Skilled Resources"
                            Text="Our web developers have 5+ years of experience making the best websites and web applications."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="60% Less Cost"
                            Text="Hiring developers can help you in reducing development costs by 60%. As a leading."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Easy Access"
                            Text="Hire the best coders and programmers from the world's leading web development company."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Higher Engagement"
                           Text="Having years of industry experience and knowledge allows us to design engaging & rewarding user experiences."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;