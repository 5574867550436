
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcessTwo from '../../components/Process/SingleProcessTwo';
import shapeImg from'../../assets/img/process/circle.png';
import valueBg from'../../assets/img/banner/value-bg.png';
                
 const Mission = () => {
                
    return (
        <div  style={{
            backgroundImage: 'url('+valueBg+')',
            backgroundSize: "cover",
            backgroundPosition:"left bottom"
        }}>
        <div className="rs-process modify1 pt-80 pb-80">
                <div className="shape-animation hidden-sm">
                    <div className="shape-process">
                         <img className="dance2" src={shapeImg} alt="images" />
                    </div>
                </div>
               <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 md-text-center pr-40 md-pr-14 md-pb-80">
                            <SectionTitle
                                sectionClass="sec-title2 text-left mb-44"
                                subtitleClass="sub-text style-bg text-white"
                                subtitle="Solution"
                                titleClass="title testi-title text-white"
                                title="On - Demand Solutions"
                                descClass="desc"
                                description="On-demand services are provided by our team of veteran on-demand application developers using most advanced technology. Here are some trending on-demand services. Provider of on-demand app development solutions that showcase your business and aspirations. Our optimization efforts empower your business by connecting you to your customers through venerable on-demand apps."
                                effectClass="heading-line"
                             />
                            
                        </div>
                        <div className="col-lg-7 sm-pl-40 sm-pr-20">
                                            <div className="row">
                                                <div className="col-md-6 mb-70 ">
                                                    <SingleProcessTwo
                                                        itemClass="rs-addon-number bg-light-c1"
                                                        prefixClass="number-area"
                                                        prefix="1"
                                                        titleClass="title pl-0 pr-0"
                                                        Title="Food & Grocery Delivery"
                                                        descClass="number-txt"
                                                        Description="Including Food and Grocery, we build feature-rich, technologically advanced home delivery applications."
                                                    />
                                                </div>
                                                <div className="col-md-6 sm-mb-70">
                                                    <SingleProcessTwo
                                                        itemClass="rs-addon-number bg-light-c4"
                                                        prefixClass="number-area plum-bg"
                                                        prefix="2"
                                                        titleClass="title"
                                                        Title="Instant Messaging App"
                                                        descClass="number-txt"
                                                        Description="The app we build include high-end features and end-to-end encryption, like WhatsApp and Telegram."
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-70">
                                                    <SingleProcessTwo
                                                        itemClass="rs-addon-number bg-light-c2"
                                                        prefixClass="number-area green-bg"
                                                        prefix="3"
                                                        titleClass="title"
                                                        Title="E-wallet Application"
                                                        descClass="number-txt"
                                                        Description="Businesses of all sizes can take advantage of our on-demand e-wallet app solutions with the best features & integrations."
                                                    />
                                                </div>
                                                
                                                <div className="col-md-6">
                                                    <SingleProcessTwo
                                                        itemClass="rs-addon-number bg-light-c3"
                                                        prefixClass="number-area pink-bg"
                                                        prefix="4"
                                                        titleClass="title"
                                                        Title="Car Booking"
                                                        descClass="number-txt"
                                                        Description="To make their car rental business more interactive & easy to manage online, we provide efficient car rental solution & dynamic car rental websites."
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
                
                export default Mission;