import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectTwo from '../../components/phpDevelopment/SingleProjectTwo';


import projectImg11 from '../../assets/img/project/11.jpg';
import projectImg12 from '../../assets/img/project/12.jpg';
import projectImg13 from '../../assets/img/project/13.jpg';
import projectImg14 from '../../assets/img/project/14.jpg';

import bannerEight from '../../assets/img/bg/cta-bg.jpg';


const Technologies = () => {

    const myStyle={
        backgroundImage: `url(${bannerEight})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right center'
    };
    const options = {
        items: 4,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: true,
            },
            768: {
                items: 2,
                nav: true,
            },
            1200: {
                items: 2,
                nav: true,
            },
            1366: {
                items: 2,
                nav: true,
            },
            1600: {
                items: 2,
                nav: true,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-case-study pt-0 pb-0" style={myStyle}>
                <div className="">
                    <div className="row margin-0 align-items-center">
                        <div className="col-lg-6 padding-0 md-mb-50">
                            <div className="case-study">
                                <SectionTitle
                                    sectionClass="sec-title2 mb-0"
                                    subtitleClass="sub-text"
                                    subtitle=""
                                    titleClass="title testi-title pb-10"
                                    title="eCommerce Sites Support Online Businesses"
                                    descClass="desc"
                                    description="You can rely on our eCommerce developers to bring your products or services to life on a digital screen. With leading eCommerce providers like Magento, WooCommerce or Shopify, we deliver this WOW experience."
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 padding-0">
                            <div className="case-study-wrap">
                                <div className="rs-project style3 modify1 mod md-pt-0">
                                    <OwlCarousel options={options} >
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg12} 
                                            Title="Magento"
                                            Text="We know every bit and pieces of Magento development services. Let’s build your Magento store."
                                            projectURL="./eCommerce-development"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg11} 
                                            Title="Woocommerce"
                                            Text="You can manage your eCommerce website content with WordPress's best eCommerce plugin." 
                                            projectURL="./eCommerce-development"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg13} 
                                            Title="Shopify" 
                                            Text="Bring your idea to life with Shopify Development. Online stores can benefit from Shopify's themes & features." 
                                            projectURL="./eCommerce-development"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg14} 
                                            Title="Custom Development"
                                            Text="E-commerce is essential for developing a business. By using it, you can attract a wide range of clients." 
                                            projectURL="./eCommerce-development"
                                        />
                                       
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </React.Fragment>
	)
}

export default Technologies;