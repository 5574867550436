import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {

	const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [services, setService] = useState(false)
	const [solution, setSolution] = useState(false)
	const [blog, setBlog] = useState(false)
	const [hiredevelopers, setHiredevelopers] = useState(false)
	const [Portfolio, setPortfolio] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setService(false)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setService(false)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(false)
		}
		else if (menu === 'services') {
			setHome(false)
			setAbout(false)
			setService(!services)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(false)
		}
		else if (menu === 'solution') {
			setHome(false)
			setAbout(false)
			setService(false)
			setBlog(false)
			setSolution(!solution)
			setHiredevelopers(false)
			setPortfolio(false)
			
		}
		else if (menu === 'blog') {
			setHome(false)
			setAbout(false)
			setService(false)
			setBlog(!blog)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(false)
		}
		
		else if (menu === 'hiredevelopers') {
			setHome(false)
			setAbout(false)
			setService(false)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(!hiredevelopers)
			setPortfolio(false)
		}
		else if (menu === 'Portfolio') {
			setHome(false)
			setAbout(false)
			setService(false)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(!Portfolio)
		}
		
	};

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						
						<li>
							<Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>Home</Link>
						</li>

						<li>
							<Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
						</li>
						
						<li className={services ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="/service" onClick={() => { openMobileMenu('services'); }}>Service</Link>
							<ul className={services ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="./web-development" className={activeMenu === "./web-development" ? "active-menu" : ""}>Web Development</Link>
								</li>
								<li>
									<Link to="/web-development" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
								</li>
								<li>
									<Link to="/php-development" className={activeMenu === "/php-development" ? "active-menu" : ""}>PHP Development</Link>
								</li>
								<li>
									<Link to="/laravel-development" className={activeMenu === "/laravel-development" ? "active-menu" : ""}>Laravel Development</Link>
								</li>
								<li>
									<Link to="/codeigniter-development" lassName={activeMenu === "/codeigniter-development" ? "active-menu" : ""}>Codeigniter Development</Link>
								</li>
								<li>
									<Link to="/wordpress-development" lassName={activeMenu === "/wordpress-development" ? "active-menu" : ""}>Wordpress Development</Link>
								</li>
								<li>
									<Link to="/mobile-app-development" lassName={activeMenu === "/mobile-app-development" ? "active-menu" : ""}>Mobile App Development</Link>
								</li>
								<li>
									<Link to="/android-app-development" lassName={activeMenu === "/android-app-development" ? "active-menu" : ""}>Android App Development</Link>
								</li>
								<li>
									<Link to="/ios-app-development" lassName={activeMenu === "/ios-app-development" ? "active-menu" : ""}>iOS App Development</Link>
								</li>
								
								<li>
									<Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>eCommerce Development</Link>
								</li>
								<li>
									<Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>UX/UI Design Development</Link>
								</li>
								<li>
									<Link to="/reactJs-development" className={activeMenu === "/reactJs-development" ? "active-menu" : ""}>ReactJs Development</Link>
								</li>
								<li>
									<Link to="/angularJs-development" className={activeMenu === "/angularJs-development" ? "active-menu" : ""}>AngularJs Development</Link>
								</li>
								<li>
									<Link to="/javaScripts-development" className={activeMenu === "/javaScripts-development" ? "active-menu" : ""}>JavaScripts Development</Link>
								</li>
								<li>
									<Link to="/vueJs-development" lassName={activeMenu === "/vueJs-development" ? "active-menu" : ""}>VueJS Development</Link>
								</li>
								<li>
									<Link to="/nodeJs-development" lassName={activeMenu === "/nodeJs-development" ? "active-menu" : ""}>NodeJs Development</Link>
								</li>
							</ul>
						</li>

						<li className={solution ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="/solution" onClick={() => { openMobileMenu('solution'); }}>Solution</Link>
							<ul className={solution ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="fintech-app-solution" className={activeMenu === "/fintech-app-solution" ? "active-menu" : ""}>Fintech-App Solution</Link>
								</li>
								<li>
									<Link to="ecommerce-solutions" className={activeMenu === "/ecommerce-solutions" ? "active-menu" : ""}>E-Commerce App Solution</Link>
								</li>
								<li>
									<Link to="business-app-solution" className={activeMenu === "/business-app-solution" ? "active-menu" : ""}>Business App Solutions</Link>
								</li>
								<li>
									<Link to="fitness-wellness-app-solutions" className={activeMenu === "/fitness-wellness-app-solutions" ? "active-menu" : ""}>Fitness App Solutions</Link>
								</li>
								<li>
									<Link to="travel-app-solutions" className={activeMenu === "/travel-app-solutions" ? "active-menu" : ""}>Travel App Solutions</Link>
								</li>
								<li>
									<Link to="real-estate-app-solution" className={activeMenu === "/real-estate-app-solution" ? "active-menu" : ""}>Real Estate App Solutions</Link>
								</li>
								<li>
									<Link to="healthcare-app-solution" className={activeMenu === "/healthcare-app-solution" ? "active-menu" : ""}>Healthcare App Solutions</Link>
								</li>
								<li>
									<Link to="social-media-app" className={activeMenu === "/social-media-app" ? "active-menu" : ""}>Social Media App Solutions</Link>
								</li>
							</ul>
						</li>

						<li className={hiredevelopers ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="/hire-dedicated-developers" onClick={() => { openMobileMenu('hiredevelopers'); }}>Hire Developers</Link>
							<ul className={hiredevelopers ? "sub-menu current-menu" : "sub-menu"}>
							<li>
									<Link to="hire-web-developers" className={activeMenu === "/hire-web-developers" ? "active-menu" : ""}>Hire Web Developers</Link>
								</li>
								<li>
									<Link to="hire-php-developers" className={activeMenu === "/hire-php-developers" ? "active-menu" : ""}>Hire PHP Developers</Link>
								</li>
								<li>
									<Link to="hire-laravel-developers" className={activeMenu === "/hire-laravel-developers" ? "active-menu" : ""}>Hire Laravel Developers</Link>
								</li>
								
								<li>
									<Link to="hire-nodejs-developers" className={activeMenu === "/hire-nodejs-developers" ? "active-menu" : ""}>Hire NodeJS Developers</Link>
								</li>
								<li>
									<Link to="hire-reactjs-developers" className={activeMenu === "/hire-reactjs-developers" ? "active-menu" : ""}>Hire ReactJs Developers</Link>
								</li>
								<li>
									<Link to="hire-angular-developer" className={activeMenu === "/hire-angular-developer" ? "active-menu" : ""}>Hire AngularJs Developers</Link>
								</li>
								<li>
									<Link to="hire-vuejs-developer" className={activeMenu === "/hire-vuejs-developer" ? "active-menu" : ""}>Hire Vue Js Developers</Link>
								</li>
								<li>
									<Link to="hire-android-app-developer" className={activeMenu === "/hire-android-app-developer" ? "active-menu" : ""}>Hire Android App Developers</Link>
								</li>
								<li>
									<Link to="hire-cross-platform-app-developers" className={activeMenu === "/hire-cross-platform-app-developers" ? "active-menu" : ""}>Hire Cross Platform App Developers</Link>
								</li>
								<li>
									<Link to="hire-ios-app-developer" className={activeMenu === "/hire-ios-app-developer" ? "active-menu" : ""}>Hire iOS App Developers</Link>
								</li>
								<li>
									<Link to="hire-wordpress-developer" className={activeMenu === "/hire-wordpress-developer" ? "active-menu" : ""}>Hire Wordpress Developers</Link>
								</li>
								<li>
									<Link to="hire-ux-ui-developers" className={activeMenu === "/hire-ux-ui-developers" ? "active-menu" : ""}>Hire UX/UI Designer</Link>
								</li>
							</ul>
						</li>

						<li className={blog ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="/blog" onClick={() => { openMobileMenu('blog'); }}>Blog</Link>
							<ul className={blog ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/blog" className={activeMenu === "/blog" ? "active-menu" : ""}>Blog</Link>
								</li>
								<li>
									<Link to="/blog/blog-details" className={activeMenu === "/blog-details" ? "active-menu" : ""}>Blog details</Link>
								</li>
							</ul>
						</li>

						<li>
							<Link to="/careers" className={activeMenu === "/careers" ? "active-menu" : ""}>Careers</Link>
						</li>
						<li>
							<Link to="/Portfolio" className={activeMenu === "/Portfolio" ? "active-menu" : ""}>Portfolio</Link>
						</li>
						<li>
							<Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
						</li>

					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;