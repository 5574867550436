import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg14 from '../../assets/img/service/style9/14.jpg';
import serviceImg13 from '../../assets/img/service/style9/13.jpg';
import serviceImg11 from '../../assets/img/service/style9/11.jpg';
import serviceImg12 from '../../assets/img/service/style9/12.jpg';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-50 ">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="A full range of services to achieve your goals"
                    titleClass="title new-title"
                    title="Effective Growth Strategies, Converting Websites & Online Marketing."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Website Design & Development"
                            btnClass="services-btn3"
                            btnText="Read More"
                            btnURL="./web-development"
                            Text="High-Quality, Techno-Driven, and Result-oriented website Development"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-pink"
                            serviceImg={serviceImg13}
                            Title="Front-End Web Development"
                            btnClass="services-btn3"
                            btnText="Read More"
                            btnURL="./front-end-development"
                            Text="Most Innovative, Intuitive & User Friendly Web application Developments"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-green"
                            serviceImg={serviceImg11}
                            Title="Mobile Application Development"
                            btnClass="services-btn3"
                            btnText="Read More"
                            btnURL="./mobile-app-development"
                            Text="Most Engaging, Appealing and Feature-rich Mobile apps Development"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-red"
                            serviceImg={serviceImg12}
                            Title="UI/UX And Graphics Designer"
                            btnClass="services-btn3"
                            btnText="Read More"
                            btnURL="./ux-ui-design"
                            Text="Most Powerful, well-designed, and Business-Oriented UI/UX designed"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;