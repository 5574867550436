import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/about/Mobile-app.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from '../mobile-app-development/Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import techImg10 from '../../assets/img/technology/style2/10.svg';
import techImg16 from '../../assets/img/technology/style2/16.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg6 from '../../assets/img/technology/style2/6.svg';
import techImg13 from '../../assets/img/technology/style2/13.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg12 from '../../assets/img/technology/style2/12.svg';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon25 from '../../assets/img/service/style2/main-img/25.png';
import serviceIcon44 from '../../assets/img/service/style2/main-img/44.png';
import serviceIcon45 from '../../assets/img/service/style2/main-img/45.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import AndroidDevelop from '../../assets/img/banner/AndroidDevelop.png';
import IosDevelop from '../../assets/img/banner/IosDevelop.png';
import CrossPlatDevelop from '../../assets/img/banner/CrossPlatDevelop.png';

const MobileAppDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-60 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Mobile App Development Solutions"
                                titleClass="title pb-25"
                                title="Make Your Big Ideas Work On Mobile Screens. We're Built That Way"
                                descClass="desc pb-5"
                                description="Developing mobile apps to get unicorn club perks is what we do. In both short- and long-term projects, our app developers deliver exceptional results."
                                secondDescClass="desc pb-16"
                                secondDescription="Our team will work with you from ideation to launch. The goal of our mobile apps is to satisfy users' needs today and to touch their hearts. Instagram Micro designs native and cross-platform mobile apps. As a cross-platform and native app developer, our app developers can assist you."
                            />
                        </div>
                    </div>
                    <div className="row pt-50">

                        <div className="col-lg-12 col-md-12 pt-25 text-center">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                titleClass="title pb-25 fz-36"
                                title="We Develop Mobile Apps To Help You Establish Your Unique Identity"
                                descClass="desc pb-5"
                                description="We develop disruptive & compelling mobile apps with modern technologies & business skills. Creating Native apps for iOS, Android, Kotlin, Swift, and Objective-C is what we do as a trusted mobile app development company. As well as this, we offer exceptional cross-platform mobile experience with apps on React Native, Xamarine, and Flutter. Our full stack mobile app services address all your requirements for building fruitful business relations with your users."                            
                            />
                        </div>

                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}


            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="Diverse Mobile App Development Services"
                        descClass="desc"
                        description="Our company offers a full range of services for developing mobile apps that are feature-rich and effective. With extensive experience in producing innovative digital solutions across multiple verticals, our developers have worked with both start-ups and market giants."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <div className="text-left pb-15">
                                        <img className="main" src={AndroidDevelop} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-40"
                                        titleClass="title pb-10 fz-28"
                                        title="Android App Development"
                                        descClass="desc"
                                        description="A variety of mobile platforms are supported by our mobile application developers, including iOS, Android & cross-platform solutions using Flutter, React Native & Ionic. As part of our iOS app development services, we use the following modern technologies:" 
                                    />
                                    <div className="rs-technology">
                                        <div className="row">            
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div className="technology-item pt-5 pb-5">
                                                   
                                                        <div className="logo-img">
                                                            <img src={techImg10} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg16} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg3} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Section Title End */}
                                    <div className="btn-part">
                                        <Link className="readon learn-more" style={{paddingTop : 8, paddingBottom : 8 }} to="./android-app-development">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 mb-30">
                                <div className="text-left pb-15">
                                        <img className="main" src={IosDevelop} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-28"
                                        title="iOS App Development"
                                        descClass="desc"
                                        description="With our team of top mobile developers, we can develop iOS apps that enhance user engagement & revenue. We provide iOS mobile apps that are fast & scalable. As part of our iOS app development services, we use the following modern technologies:"  
                                    />
                                    <div className="rs-technology">
                                        <div className="row">            
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div className="technology-item">
                                                   
                                                        <div className="logo-img">
                                                            <img src={techImg6} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item pt-5 pb-5">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg10} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg3} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-part">
                                        <Link className="readon learn-more" style={{paddingTop : 8, paddingBottom : 8 }} to="./ios-app-development">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 mb-20"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="text-left pb-15">
                                    <img className="main" src={CrossPlatDevelop} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-28"
                                        title="Cross-platform App Development"
                                        descClass="desc"
                                    description="Developing custom apps for both Android and iOS platforms will help you reach a wide audience. With our cross-platform app solutions, you'll get a reduced time-to-market and an affordable price. A cross-platform solution can be developed using:"  
                                    />
                                    <div className="rs-technology">
                                        <div className="row">            
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                <div className="technology-item">
                                                   
                                                        <div className="logo-img">
                                                            <img src={techImg3} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                <div className="technology-item">
                                                   
                                                        <div className="logo-img">
                                                            <img src={techImg16} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg12} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg13} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg8} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg4} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                   
                                                        <div class="logo-img">
                                                        <img src={techImg9} alt="" />
                                                        </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                            </div>
                            <div className="col-lg-2 mb-20"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-60"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title w-80"
                        title="Up skill Your Idea or Existing Team With Our Mobile Power"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="Mobile app development is something we specialize in offering to clients across multiple industry verticals worldwide. With our comprehensive service stack, we can help you take your business's challenges & turn them into opportunities."
                    />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Proven Experience"
                                Text="We have 90% mobile app developers with 6-8 years of experience developing and deploying thousands of apps."
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon45}
                                Title="Proactive Approach" 
                                Text="As the mobile app landscape changes, our developers easily adapt and deliver extraordinary results." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon44}
                                Title="Mobile-First Team" 
                                Text="Known for delivering quality services, we are a team that develops mobile apps for a variety of platforms." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon25}
                                Title="Result-Drive" 
                                Text="We provide customized solutions tailored to your industry's individual needs, giving you a competitive edge." 
                            />
                        </div>
                    </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-servicess-area-start */}
            <div className="rs-services style8 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated Mobile App Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title5"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default MobileAppDevelopment;