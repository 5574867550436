import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/travel.webp';
import valueBg from'../../assets/img/banner/value-bg.png';

const AboutSlider = () => {

    return (
        <React.Fragment>
            <div className="style3 pt-50 pb-50 md-pt-80 md-pb-80" style={{
                    backgroundImage: 'url('+valueBg+')',
                    backgroundSize: "cover",
                    backgroundPosition:"left bottom"
                }}>
                <div className="container relative">
                    <div className="sec-left">
                        <h2 className="title ">What's We Provide</h2>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 "
                                    title="Our Travel Mobile App Development Solutions"
                                    descClass="desc "
                                    description="Besides retaining existing customers, we offer comprehensive travel app development solutions that attract new ones as well. Utilizing digital platforms that spread product and service innovation, we use technologies to boost customer satisfaction and improve their experience."
                                />
                                </div>
                                <div className='col-lg-7 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Currency converter</li>
                                        <li className="">Real-time push notifications</li>
                                        <li className="">Secure payment gateway integration</li>
                                        <li className="">GPS based location services</li>
                                        <li className="">Virtual tour guide</li>
                                        <li className="">Reviews And Recommendations</li>
                                    </ul>
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <ul className="check-square">
                                        <li className="">Trip planning</li>
                                        <li className="">Travel diary</li>
                                        <li className="">Tour booking</li>
                                        <li className="">Travel assistant</li>
                                        <li className="">Travel photography</li>
                                        <li className="">Social Media Integration</li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}





                                                                                                                                                
    
export default AboutSlider