import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/about/e-Commerce-main.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain26 from '../../assets/img/service/style2/main-img/26.png';
import imgMain37 from '../../assets/img/service/style2/main-img/37.png';
import imgMain33 from '../../assets/img/service/style2/main-img/33.png';
import imgMain10 from '../../assets/img/service/style2/main-img/10.png';
import imgMain39 from '../../assets/img/service/style2/main-img/39.png';


// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
import  ecommBackImg from '../../assets/img/about/ecommBackImg.jpg';

const eCommerceDevelopment = () => {
   
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                       <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="Get High Revenues From Advanced e-Commerce Development Solutions"
                                descClass="desc pb-5"
                                description="Our experienced developers will design and build the perfect ecommerce website for your venture and implement the latest technologies to ensure profitability. To ensure that you as the digital merchant can fully control your webstore, we compute your user experience."
                                secondDescClass="desc pb-16"
                                secondDescription="Business development is impossible without e-commerce. This is the most effective method of attracting customers from a wide demographic. Providing ecommerce strategy, integration, migrations, support, and a full range of ecommerce website development services is what we do best."
                            />
                        </div>
                        
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="End-to-End eCommerce Development Services"
                        descClass="desc"
                        description="Our eCommerce development services meet the significant requirements of clients across the globe with the right features & functionalities. Our eCommerce development company offers a wide range of services if you are looking for an eCommerce company."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain10}
                                    HoverImg={imgMain10}
                                    Title="e-Commerce Website Design"
                                    Text="Consumers & clients mainly interact via the website. Our web designs are designed to attract the consumer's attention at first glance. We promise to provide you with a first-time user experience of the highest quality to help your business grow."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6  mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain33}
                                    HoverImg={imgMain33}
                                    Title="e-Commerce Apps"
                                    Text="Mobile applications are the easiest way to engage consumers in the technology world. High-quality developers have integrated features such as cameras, GPS trackers, and microphones into eCommerce apps to keep track of customer actions."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6  mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain37}
                                    HoverImg={imgMain37}
                                    Title="End-to-End eCommerce Development"
                                    Text="Each solution we create, from eCommerce app development to website development, is designed to deliver engaging user experiences. The development process is implied with proper code execution to build what the client desires."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6  mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain26}
                                    HoverImg={imgMain26}
                                    Title="Plug-in & Module Development"
                                    Text="Optimize your website's functionality with an e-Commerce plugin and module. By developing custom plugins and modules, we maximize your business potential. Developer-built modules for your core system that fit your needs."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6  mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain39}
                                    HoverImg={imgMain39}
                                    Title="e-Commerce SEO Services"
                                    Text="Our expertise in Search Engine Optimization gives your website a uniform edge by giving it top visibility on Google, Bing, Yahoo, etc. Besides on-page optimization, we also do link building & many other SEO strategies in the same domain."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6  col-md-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Leading e-Commerce Development Provides A Better Buying Experience"
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            <Technologies />

             {/* rs-services-area-start */}
             <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why Choose Us for Development?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Experience & Expertise"
                            Text="With over a decade of experience in e-commerce development, we can build most sophisticated e-commerce stores for clients in all business niches & sizes."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Dedicated E-commerce Process" 
                            Text="An industry-leading team of e-commerce experts with the latest tools, languages & methods manage our e-commerce development process." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon15}
                            Title="Full Life Cycle of e-Commerce" 
                            Text="To ensure optimum success of e-commerce apps, we provide comprehensive and end-to-end e-commerce development services." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Competitive Rate Of Development" 
                            Text="Our company provides e-commerce and mobile commerce services with high value and high-quality at highly competitive prices." 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}

            {/* rs-services-area-start */}
            <div className="choose-area-1 pt-60 pb-40">
                <div className="choose-img "><img  src={ecommBackImg} /></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                          
                        </div>
                        <div className="col-lg-6 pt-25 pb-25 choose-right">
                            <div className="choose-right-content">
                                <SectionTitle
                                    sectionClass="sec-title3 text-left "
                                   titleClass="title text-white"
                                    title="Benefits of e-Commerce Apps"
                                    descClass="desc text-white"
                                    description="It allows small and large businesses to gain instant visibility and showcase their products, brands, and services online, regardless of their size." 
                                />
                                <ul>
                                    <li> With E-commerce solution, it is trouble-free to endorse your business online.</li>
                                    <li>SEO Services Your customer can reach to you unswervingly</li>
                                    <li>SEO Company in India Accessible to consumers 24×7</li>
                                    <li>SEO Services in Delhi Cost-efficient services</li>
                                    <li>SEO Company Easy dispersion of the market</li>
                                    <li>SEO CompanyA knowledgeable and aware customer base</li>
                                    <li>SEO CompanyEasy to market your business through Search Engine Optimization (SEO), Social Media</li>
                                  
                                </ul>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-end */}
            
            {/* rs-servicess-area-start */}
           <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-60 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire The Dedicated E-Commerce Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}
                       
            {/* working-process-area-start */}
            <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default eCommerceDevelopment;