import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';


const DevelopmentServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-0 pb-50">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="Technologies We are working with"
                        titleClass="title pb-10"
                        title="Bringing Strategy & Creativity together. Web Development Services are what we do."
                        descClass="desc"
                        description="We offer you a wide range of Web Development Services at the most affordable rates. Take a look at them."
                        effectClass="heading-line"
                />
                <div className="row align-items-center">

                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Web Design"
                                    Text="Following your unique design needs, brand guidelines, & goals, our Website Designers craft visually appealing conversion-generating designs."
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Back-end Web Development"
                                    Text="We provide you with the best results regardless of whether your needs are data imports or custom functionality."
                                />
                            </div>
                            
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Front-end Web Development"
                                    Text="The right expertise in HTML, CSS, and Javascript allows our Web Development Agency to bring your vision to life."
                                />
                            </div>
                           
                        </div>
                    </div>
                   
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Full-cycle Web Development"
                                    Text="Providing full-cycle development services, our Web Development Agency is there to support you every step of the way."
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Enterprise Solution Development"
                                    Text="Leverage the best experts to develop affordable enterprise-class web solutions with our Web Development Agency."
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Maintenance & Support"
                                    Text="Keeping your website health in check is our priority and we provide 24/7 support and timely maintenance."
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DevelopmentServices;