import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-80 pb-50">
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our Android App Development Services"
                        descClass="desc mb-25"
                        description="Hire Android App Programmer that provides you skillful development of innovative applications that run seamlessly over Android devices – irrespective of device brand, Android version, and screen sizes. Check out our Android app development services."
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Android App Development & Design"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Wearable App Development"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Enterprise Mobility Management"
                                 />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Ecommerce & AI App Development"
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="On-Demand App Development"
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="QA & Testing"
                                 />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default OurServices;