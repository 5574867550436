import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/e-Commerce-main.webp';
import valueBg from'../../assets/img/banner/value-bg.png';

const AboutSlider = () => {
    return (
        <React.Fragment>
            <div className="style3 pt-50 pb-80 md-pt-80 md-pb-80" style={{
            backgroundImage: 'url('+valueBg+')',
            backgroundSize: "cover",
            backgroundPosition:"left bottom"
        }}>
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title  ">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 "
                                    title="E-commerce Application"
                                    descClass="desc "
                                    description="The latest theme, functionality, features, and support services we provide will help you effectively stay up with your audience and retain existing customers. We build ecommerce mobile apps that are engaging, real-time, and encourage users to return to your app frequently. Our ecommerce mobile apps enable multiple vendors to sell their different products online in a global marketplace."
                                />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <ul className="check-square">
                                        <li className=" ">E-catalogs</li>
                                        <li className=" ">Products Gallery</li>
                                        <li className=" ">Advanced Product Search</li>
                                        <li className=" ">Multiple Purchase Options</li>
                                        <li className=" ">Add to Favorites</li>
                                        <li className=" ">Multi-Currency Support</li> 
                                        <li className=" ">User-friendly app navigation</li>
                                        <li className=" ">Reports & analytics</li>
                                    </ul>
                                </div>
                                <div className='col-lg-7 col-md-6'>
                                    <ul className="check-square">
                                        <li className=" ">Social Media Login</li>
                            
                                        <li className=" ">Order Tracking & Management</li>
                                        <li className=" ">Coupons and Promo Codes</li>
                                        <li className=" ">Multiple Payment Options Support</li>
                                        <li className=" ">Real-Time Order Monitoring</li>
                                        <li className=" ">Multi-currency and Multilingual support</li> 
                                        <li className=" ">Complete security includes transactions and payment</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider