import SectionTitle from '../../components/Common/SectionTitle';

import techImg1 from '../../assets/img/technology/style2/1.svg';
import techImg2 from '../../assets/img/technology/style2/2.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg5 from '../../assets/img/technology/style2/5.svg';
import techImg6 from '../../assets/img/technology/style2/6.svg';
import techImg7 from '../../assets/img/technology/style2/7.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg10 from '../../assets/img/technology/style2/10.svg';
import techImg11 from '../../assets/img/technology/style2/11.svg';
import techImg12 from '../../assets/img/technology/style2/12.svg';
import techImg13 from '../../assets/img/technology/style2/13.svg';
import techImg14 from '../../assets/img/technology/style2/14.svg';
import techImg15 from '../../assets/img/technology/style2/15.svg';
import techImg16 from '../../assets/img/technology/style2/16.svg';
import techImg17 from '../../assets/img/technology/style2/17.svg';
import techImg18 from '../../assets/img/technology/style2/18.svg';
import techImg19 from '../../assets/img/technology/style2/19.svg';
import techImg20 from '../../assets/img/technology/style2/20.svg';
import techImg21 from '../../assets/img/technology/style2/21.svg';
import techImg22 from '../../assets/img/technology/style2/22.svg';
import techImg23 from '../../assets/img/technology/style2/23.svg';
import techImg24 from '../../assets/img/technology/style2/24.svg';
import techImg25 from '../../assets/img/technology/style2/25.svg';
import techImg26 from '../../assets/img/technology/style2/26.svg';
import techImg27 from '../../assets/img/technology/style2/27.svg';
import techImg28 from '../../assets/img/technology/style2/28.svg';
import techImg29 from '../../assets/img/technology/style2/29.svg';
import techImg30 from '../../assets/img/technology/style2/30.svg';
import techImg31 from '../../assets/img/technology/style2/31.svg';
import techImg32 from '../../assets/img/technology/style2/32.svg';
import techImg33 from '../../assets/img/technology/style2/33.svg';



const Technology = (props) => {
    return (
        <div className="rs-technology pt-50 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text style-bg"
                    subtitle="Technology Index"
                    titleClass="title title"
                    title="What Technology We Are Using For Our Valued Customers"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            
                                <div className="logo-img">
                                    <img src={techImg1} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg2} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            
                                <div className="logo-img">
                                    <img src={techImg3} alt="" />
                                </div>
                            
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg4} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg5} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            
                                <div className="logo-img">
                                    <img src={techImg12} alt="" />
                                </div>
                            
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg8} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg9} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg15} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg6} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg7} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg10} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg13} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg16} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg17} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg18} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg19} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg20} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg21} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg22} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg23} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg24} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg25} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg26} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg27} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg28} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg29} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg31} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item p-0">
                           
                                <div className="logo-img" style={{ width: 115,margin: 10}}>
                                    <img src={techImg32} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                           
                                <div className="logo-img">
                                    <img src={techImg33} alt="" />
                                </div>
                           
                        </div>
                    </div>
                    {/* <div className="col-lg-12 text-center">
                    <div class="btn-part mt-40"><a class="readon learn-more" href="/contact">Start Conversation</a></div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Technology