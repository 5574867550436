import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-50 pb-50">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our AngularJS Development Services"
                        descClass="desc mb-25"
                        description="AngularJS is an open-source technology that is maintained by Google. It says a lot about the quality of the framework. However, to leverage the platform to its fullest you need to hire top AngularJS developers as we offer at Mataqualt."
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Web App Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Plugin Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Single Page Applications"
                                 />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div> 
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>       
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Version Migration"
                                />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="QA and Testing"
                                />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="App Maintenance & Support"
                                 />
                            </div>
                        </div>
                    </div>   
                    
                </div>
            </div>
        </div>
    );
}

export default OurServices;