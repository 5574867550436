import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactUsForm from '../../components/Contact/ContactUsForm';
import ContactInfo from '../../components/Contact/ContactInfo';
import ScrollToTop from '../../components/Common/ScrollTop';
// import CounterDefault from '../../components/Elements/Counter/CounterDefault';
import Brand from '../../components/Common/Brand';
import bannerone from '../../assets/img/bg/testi12-bg.jpg';


const ContactMain = () => {

     return (
            <React.Fragment>

                {/* Contact Section Start */}
                <div className="rs-contact rs-why-choose style2 pt-120 pb-80 md-pt-60">
                    <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-60"
                            subtitleClass="sub-text style-bg"
                            subtitle="Let's start the discussion"
                            titleClass="title title"
                            title="We are ready to Lead You into the future of Technology"
                            effectClass="heading-line" 
                            descClass="desc"
                            description="Have questions about your idea? Drop in your details to discuss with our solution consultants. We look forward to hear from you."
                            
                        />
                        <div className="row bx-shadow">
                            <div className="col-lg-5 col-md-12 md-mb-60">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-7 col-md-12 pl-30 md-pl-14">
                                <div className="contact-widget">
                                     {/* Contact Form */}
                                    <ContactUsForm />
                                    {/* Contact Form */}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* Contact Section End */}

                {/* Contact Section Start */}
                <div className="relative pt-80 pb-80 mb-0" style={{backgroundImage: `url(${bannerone})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right center'}}>
                        
                        <div className="container">
                            <div className="row">
                              
                                <div className="col-lg-12">
                                    <div className="rs-contact-box text-center">
                                        
                                        <SectionTitle
                                            sectionClass="sec-title2 text-center"
                                            subtitleClass="sub-text style-bg"
                                            subtitle="24/7 Availability"
                                            titleClass="title"
                                            title="You've Finally Found The Most Affordable Website Design & Development Company in India. Make Your Dream a Reality By Connecting With Our Experts."
                                            effectClass="heading-line" 
                                         />
                                       {/* <div className="btn-part mt-40">
                                            <Link className="readon learn-more" to="/contact">Start Conversation</Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                {/* Contact Section End */}
                <Brand />
                {/* scrolltop-start */}
                 <ScrollToTop />
                {/* scrolltop-end */}


            </React.Fragment>

        );
}


export default ContactMain;