import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/banking-app.png';
import aboutImg2 from '../../assets/img/about/finance-app.png';
import aboutImg3 from '../../assets/img/about/payment-app.png';
import aboutImg4 from '../../assets/img/about/wallet-app.png';

import valueBg from'../../assets/img/banner/value-bg.png';

const AboutSlider = () => {
    const options = {
        items: 1,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0, 
        loop: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            768: {
                items: 1,
                nav: true,
            },
            992: {
                items: 1,
                nav: true,
            },
        }
    };

    return (
        <React.Fragment>
            <div className="style3 pt-80 pb-50 md-pt-80 md-pb-80" style={{
            backgroundImage: 'url('+valueBg+')',
            backgroundSize: "cover",
            backgroundPosition:"left bottom"
        }}>
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title  ">What's We Provide</h2>
                </div>
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Features"
                        titleClass="title pb-10"
                        title="Top Features Of Fintech Mobile Applications"
                        descClass="desc"
                        description="Our work has helped clients increase sales significantly in multiple projects. We develop FinTech apps that facilitate the access to, the security of, and the speed of financial transactions. As part of our Fintech apps, we always include the best features that will give you the best experience. We aim to deliver best-in-class financial app development services based on your business objectives. Every fintech application we develop is always aimed at perfection."
                        effectClass="heading-line"
                    />
                    <OwlCarousel options={options} >
                        <div className='single-slider '>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg1}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-30 pt-50 pb-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2   pb-16"
                                            title="Banking App"
                                            descClass="desc2  "
                                            description="Providing a seamless and futuristic banking application with attractive features and an eye-catching design is just what you need as the corporate world evolves."
                                        />
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6'>
                                                <ul className="check-square">
                                                    <li className=" ">Easy Managing Accounts</li>
                                                    <li className=" ">View Transaction History</li>
                                                    <li className=" ">Statement & Documents</li>
                                                    <li className=" ">Check account balances</li>
                                                    
                                                </ul>
                                            </div>
                                            <div className='col-lg-6 col-md-6'>
                                                <ul className="check-square">
                                                    <li className=" ">Transfer Funds</li>
                                                    <li className=" ">Products & Services</li>
                                                    <li className=" ">Fixed Deposits & Interests</li>
                                                    <li className=" ">Secure Login</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg2}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-30 pt-50 pb-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 pb-16"
                                            title="Finance App"
                                            descClass="desc2  "
                                            description="Financial industry expertise and acumen enable us to provide firms with more than just useful financial apps for planning and managing their finances."
                                        />
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6'>
                                                <ul className="check-square">
                                                    <li className=" ">Stock Market Report</li>
                                                    <li className=" ">Online Stock Market</li>
                                                    <li className=" ">Products and Services</li>
                                                    <li className=" ">Create/Edit Portfolio</li> 
                                                </ul>
                                            </div>
                                            <div className='col-lg-6 col-md-6'>
                                                <ul className="check-square">
                                                    <li className=" ">Invoice Management</li>
                                                    <li className=" ">Reports and Analytics</li>
                                                    <li className=" ">Advanced Customize Setting</li>
                                                    <li className=" ">News & Market Dashboard</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg3}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-30 pt-50 pb-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 pb-16"
                                            title="Payment Gateway"
                                            descClass="desc2"
                                            description="You will be able to conduct transactions freely with our integrated payment gateway service, as well as benefit from an unparalleled level of security."
                                        />
                                         <div className='row'>
                                            <div className='col-lg-5 col-md-6'>
                                                <ul className="check-square">
                                                    <li>Multiple Payment Options</li>
                                                    <li>Encryption Standards</li>
                                                    <li>Easy & Convenient to Use</li>
                                                    <li>Multi Currency Processing</li>
                                                </ul>
                                            </div>
                                            <div className='col-lg-7 col-md-6'>
                                                <ul className="check-square">
                                                    <li>Fraud Detection or Management System</li>
                                                    <li>Cost or fee of conducting transaction</li>
                                                    <li>Choice of Language Selection</li>
                                                    <li>Speed & Invoice Capabilities</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg4}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-30 pt-50 pb-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2   pb-16"
                                            title="Wallet App"
                                            descClass="desc2  "
                                            description="With our digital Fintech studio, we develop all-in-one banking solutions that are user-friendly and technologically advanced."
                                        />
                                        <div className='row'>
                                            <div className='col-lg-5 col-md-5'>
                                                <ul className="check-square">
                                                    <li className=" ">Create a Virtual Card</li>
                                                    <li className=" ">Send & Receive Money</li>
                                                    <li className=" ">Tokenization</li>
                                                    <li className=" ">QR Code Scan</li>
                                                </ul>
                                            </div>
                                            <div className='col-lg-7 col-md-7'>
                                                <ul className="check-square">
                                                    <li className=" ">Pay your Bills Easily</li>
                                                    <li className=" ">Real-time Balance Check</li>
                                                    <li className=" ">Transfer Money into Bank Account</li>
                                                    <li className=" ">Discount Offers & Loyalty Programs</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider