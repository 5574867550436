import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/about/node-main-abut.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from '../front-end-development/Technologies';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import WhyChoose from '../php-development/whychooseus';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';
// Service Icons
import imgMain26 from '../../assets/img/service/style2/main-img/26.png';
import imgMain27 from '../../assets/img/service/style2/main-img/27.png';
import imgMain28 from '../../assets/img/service/style2/main-img/28.png';
import imgMain29 from '../../assets/img/service/style2/main-img/29.png';
import imgMain30 from '../../assets/img/service/style2/main-img/30.png';

// Service Image Icons

import serviceIcon14 from '../../assets/img/service/style2/main-img/34.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/35.png';
import serviceIcon24 from '../../assets/img/service/style2/main-img/36.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

// Service Icons
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const NodeJs = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-80">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center pt-60">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 pl-20 md-pl-5">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Next-Gen Node.js Development Services"
                                titleClass="title pb-25"
                                title="Creating Fast, Scalable & Real-Time Node.js App is What We Do Best."
                                descClass="desc pb-5"
                                description="Node.js is a JavaScript-based server system that runs on a variety of platforms. As a node.js development company, MindInventory relies heavily on this system for the best software development services for our clients. NodeJS has been one of the most popular JavaScript frameworks since its release, offering an easy working environment. Real-time apps running on a variety of devices work best with it."
                                secondDescClass="desc pb-16"
                                secondDescription="We make sure that our top NodeJS developers make you the product you want, while including all the features you want. By using Node JS, we create user-friendly websites and web apps, that can attract a large number of users. With our secure, fast, and real-time node.js development services, you can stay ahead of your competitors. Our node.js backend and server-side solutions are feature-rich, scalable, and high-end."
                            />
                        </div>
                        
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
           
           {/* rs-servicess-area-start */}
           <div className="rs-process style6 pt-50 pb-80 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="Our Node JS Development Services"
                        descClass="desc"
                        description="Our team of NodeJS developers is one of the best in the industry, and they have deep knowledge of how NodeJS components work."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain29}
                                    HoverImg={imgMain29}
                                    Title="Node JS Web Development"
                                    Text="Using the latest NodeJS technologies and best practices, our NodeJS experts create effective and customized websites."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="Node JS Dynamic UI/UX"
                                    Text="Our experts strive to make UI/UX truly dynamic by utilizing the Google Chrome V8 JavaScript Engine to create dynamic UI/UX designs."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                     MainImg={imgMain26}
                                    HoverImg={imgMain26}
                                    Title="Node JS Real-Time Apps Solution"
                                    Text="Node JS now makes it easier to develop responsive real-time apps, and our developers are adept at developing them."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain27}
                                    HoverImg={imgMain27}
                                    Title="Custom Node JS Development Solutions"
                                    Text="We offer custom Node JS development solutions to meet the needs of businesses with different requirements."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain30}
                                    HoverImg={imgMain30}
                                    Title="NodeJS Migration Solutions"
                                    Text="Embrace NodeJS's unique features and migrate existing web apps. Your web app can be seamlessly migrated using this framework."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Delivering Next-Generation Node.js Services To Meet Your Business Objectives"
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-60"
                            subtitleClass="sub-text style-bg"
                            subtitle="We Build Fast & Reliable Web Applications"
                            titleClass="title title2"
                            title="Working with NodeJS Web Application Development"
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="We develop custom web applications using a standard approach based on NodeJS. Flow of work is planned and consists of the following steps."
                        />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Send Us Your Project Requirements"
                                Text="We will review your project requirements thoroughly and select the best developer for your project."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="Terms & Timeframes For Engagement" 
                                Text="You can choose the timelines of your project based on the consultation provided by analysts. We offer milestone options for your convenience." 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon24}
                                Title="Secure Payment Methods" 
                                Text="With multiple payment options available to you, our payment methods are highly secure & confidential. If you pay us immediately, we'll begin your project." 
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-services-area-start */}
            <WhyChoose /> 
            {/* rs-services-area-start */}

            {/* rs-servicess-area-start */}
             <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated Node.JS Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default NodeJs;