import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon40 from '../../assets/img/service/style2/main-img/40.png';
import mainIcon43 from '../../assets/img/service/style2/main-img/43.png';
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-50 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title"
                    title="Why Hire UX/UI Developer from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="User-Centric Approach"
                           Text="Hire user interface designers that help you in designing web and mobile applications that evoke action from your visitors."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon43}
                            hoverIcon={mainIcon43}
                            Title="Decade of Experience"
                           Text="We have seen the trends change; we have seen been a catalyst in the process of instilling modern design solutions. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="03"
                            mainIcon={mainIcon40}
                            hoverIcon={mainIcon40}
                            Title="Wide-Ranging Expertise"
                            Text="We have experience in more than 15 industries which helps us in including trends that are currently changing your industry."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="On-Time Delivery"
                            Text="We understand your time is precious. Consequently, we keep our promises to you & deliver the product on time."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon4}
                            hoverIcon={mainIcon4}
                            Title="Use of Advanced Technologies"
                           Text="We make sure you are abreast with the technology, & our technology consultants would provide you the right solution."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Higher Engagement"
                           Text="Having years of industry experience and knowledge allows us to design engaging & rewarding user experiences."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;