import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/about/andriod-app.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from '../mobile-app-development/Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import WhyChoose from '../php-development/whychooseus';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// tech Icons
import techImg10 from '../../assets/img/technology/style2/10.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg16 from '../../assets/img/technology/style2/16.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg18 from '../../assets/img/technology/style2/18.svg';

// Service Icons
import imgMain42 from '../../assets/img/service/style2/main-img/42.png';
import imgMain46 from '../../assets/img/service/style2/main-img/46.png';
import imgMain47 from '../../assets/img/service/style2/main-img/47.png';
import imgMain48 from '../../assets/img/service/style2/main-img/48.png';
import imgMain30 from '../../assets/img/service/style2/main-img/30.png';


// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon24 from '../../assets/img/service/style2/main-img/24.png';
import serviceIcon32 from '../../assets/img/service/style2/main-img/32.png';
import serviceIcon45 from '../../assets/img/service/style2/main-img/45.png';


// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';


const AndroidAppDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-50">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Make Yours Android App Differently"
                                titleClass="title pb-25"
                                title="Achieving Success Through Android Apps."
                                descClass="desc pb-5"
                                description="Developing custom Android apps allows you to develop feature-rich and high-performing applications. Throughout the entire lifecycle of your business, we provide you with end-to-end android development services, from consultation to development to maintenance."
                                secondDescClass="desc pb-16"
                                secondDescription="The Android application developers at our company have the ability to create apps that not only attract users' attention, but also generate high revenue. We use the best tools and methodologies when developing Android apps for your business so that they are feature-rich and error-free. We develop high-end Android apps using programming languages such as Kotlin, Java, C, C++, HTML, and CSS. We can design the most complex or the simplest application based on our expertise in both. Our goal is to make your idea a reality."
                            />
                        </div>
                        
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>

            {/* <!-- banner section end --> */}

            {/* rs-technology-area-start */}
            <div className="rs-technology pt-30 pb-30">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="Robust Tech Stack to Power Your Android Development Software"
                        effectClass="heading-line"
                    />
                    <div className="row">            
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                    
                                        <div className="logo-img">
                                            <img src={techImg18} alt="" />
                                        </div>
                                   
                                </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                    
                                        <div className="logo-img">
                                            <img src={techImg3} alt="" />
                                        </div>
                                   
                                </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                
                                    <div class="logo-img">
                                        <img src={techImg4} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                
                                    <div class="logo-img">
                                        <img src={techImg8} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                        
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item pt-5 pb-5">
                                
                                    <div class="logo-img">
                                        <img src={techImg10} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                
                                    <div class="logo-img">
                                        <img src={techImg16} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-technology-area-start */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="Become a Part Of The Superior Android Application Development Services"
                        descClass="desc"
                        description="A diverse range of businesses can benefit from our years of experience in Android app development."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain46}
                                    HoverImg={imgMain46}
                                    Title="Android App Development"
                                    Text="Developing high-quality Android apps is what we do. Designed to grow along with your business, each app is intuitive, engaging, and scalable."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain42}
                                    HoverImg={imgMain42}
                                    Title="UI & UX Design Services"
                                    Text="It's just that good - our UI/UX team creates interactive prototypes that make you think you're interacting with a real app."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain47}
                                    HoverImg={imgMain47}
                                    Title="Quality Assurance and Testing"
                                    Text="Ensure the quality of an Android application by ensuring robust debugging testing and an unparalleled experience."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain48}
                                    HoverImg={imgMain48}
                                    Title="Android Apps Support & Maintenance"
                                    Text="Commitment is more important than flings. We'll keep your app up-to-date with new technologies & trends so that you remain relevant with clients."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain30}
                                    HoverImg={imgMain30}
                                    Title="3rd Party Integrations "
                                    Text="You can integrate payments, marketing tools, sales tools, and more with us through secure 3rd party integrations."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Professional Excellence Combined with Next-Gen Android Development"
                                    effectClass="heading-line"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-60"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Up skill Your Idea or Existing Team With Our Mobile Power"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="Mobile app development is something we specialize in offering to clients across multiple industry verticals worldwide. With our comprehensive service stack, we can help you take your business's challenges & turn them into opportunities."
                    />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="100% Client Satisfaction"
                                Text="With our presence in 35+ countries & highest client satisfaction rate, we have made a name for ourselves. With our superior app quality & services, we are widely trusted around the world."
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon45}
                                Title="Expert Android App Developers" 
                                Text="One of the world's finest developer pools. Our team of developers & designers are capable of developing high-quality, feature-rich applications with an optimal return on investment. " 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon32}
                                Title="Advanced Tools & Technologies " 
                                Text="As an Android app developer, we use Agile methodologies and advanced tools and technologies which help us reduce the development time and cost." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon24}
                                Title="24 X 7 Support & Assistance " 
                                Text="Our 24-hour support & assistance ensures maximum application uptime. Your app will be bug-free & up & running throughout the year, which will improve clients retention." 
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-END */}
            
            {/* rs-services-area-start */}
            <WhyChoose />
            {/* rs-services-area-END */}

            {/* rs-servicess-area-start */}
           <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated Android App Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default AndroidAppDevelopment;