import React from "react";
import TypeWriter from "typewriter-effect";

const TypeEffect=()=> {
  return (
    <div className="typewriter">

      <TypeWriter

        onInit={(typewriter)=> {

        typewriter

        .typeString("Web Development") 
        .pauseFor(1500)
        .deleteAll()
        .typeString("Mobile App Development")
        .pauseFor(1000)
        .deleteAll()
        .typeString("UI/UX Design")
        .pauseFor(500)
        .deleteAll()
        .typeString("WordPress Development")  
            
        .start();
        
        } }
       />
    </div>
  );
}
  
export default TypeEffect;