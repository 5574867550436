import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-80 pb-50">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our VueJS Development Services"
                        descClass="desc mb-25"
                        description="Hire VueJS web developer with knowledge and experience in building amazing web applications. We offer you end-to-end web development services powered by VueJS framework."
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="VueJS Single Page Apps Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="VueJS Storefront Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="VueJS Support and Maintenance"
                                 />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div> 
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="VueJS Custom Applications"
                                 />
                            </div>
                        
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="VueJS Consultation"
                                />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="VueJS Migration"
                                />
                            </div>
                        </div>
                    </div>   
                    
                </div>
            </div>
        </div>
    );
}

export default OurServices;