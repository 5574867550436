import React, { useState } from 'react';

import SingleServiceNumber from '../../components/Service/ServiceNumber';
import SectionTitle from '../../components/Common/SectionTitle';
import MobileImg from '../../assets/img/process/work-process.png';

const Process = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div id="rs-portfolio" className="rs-process style4 pt-60 pb-60 md-pt-60 md-pb-60">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-44 md-mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title"
                        title="How we Works "
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        effectClass="heading-line"
                />
                <div className="row align-items-center">

                    <div className="col-lg-12 col-md-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Discover"
                                    Text="To discover your needs and know your exact requirements, we will plan a series of discussions"
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Define"
                                    Text="Our step-by-step approach ensures the project utilizes the best technologies and stays on track."
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Design"
                                    Text="According to the requirements of clients, we design user-centric experiences built on research."
                                />
                            </div>
                            
                            <div className="col-lg-4 col-md-6 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Develop"
                                    Text="Based on our customer-centric experiences, we provide full-featured engineering solutions."
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Deploy"
                                    Text="Testing & deploying the software step-by-step implementation process ensures a smooth deployment process."
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Deliver"
                                    Text="Software delivered by us is top-notch for smooth functioning and after-launch maintenance. "
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;