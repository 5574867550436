import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-50 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title"
                    title="Why Hire Dedicated Developer From Metaqualt?"
                    descClass="desc"
                    description="Unlock the benefits by hiring dedicated developers from us and shape your business ideas into reliable and profitable software solutions. Let’s have a look at some reasons to hire our developers for your business:"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Dedicated Team"
                            Text="We have a dedicated development team who are well versed in delivering high-end business solutions on several frameworks and technologies."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="On Time Delivery"
                           Text="Our team of designers and developers leverage the cutting-edge technologies and agile methodology that help us deliver 95% of the projects on time. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Flexible Engagement Models"
                            Text="Instead of working on unwanted rigid & strict parameters, we offer flexibility to our clients to select from different engagement & hiring models. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile & Adaptive Development"
                            serviceURL="#"
                           Text="We are here for your clear vision and idea. Get cross-functional agile development teams to keep your project running faster while diminishing unwanted practices."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Cost-Effective"
                            Text="The quick and easy onboarding of your virtual team turns out to be cost-effective as you can save on infrastructure, hiring and staffing. Scale your team in a few days at a relatively cheaper cost."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Absolute Project Control"
                           Text="With a team of remote developers, you can plan and develop the project as per your business needs. The hired development team can work ceaselessly with you for delivery and post-deployment support."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;