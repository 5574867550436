import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlip from '../../components/Service/SingleServiceFlip';

import serviceIcon1 from '../../assets/img/service/main-home/softwere-devlop.png';
import serviceIcon2 from '../../assets/img/service/main-home/web-coding.png';
import serviceIcon3 from '../../assets/img/service/main-home/mobile-coding.png';
import serviceIcon4 from '../../assets/img/service/main-home/cms-system.png';
import serviceIcon5 from '../../assets/img/service/main-home/ux-ui.png';
import serviceIcon6 from '../../assets/img/service/main-home/soft-testing.png';

import shapeImg from '../../assets/img/service/s2.png';

const ServiceFlip = () => {

    return (
        <div id="rs-service" className="rs-services main-home style2 pt-80 pb-50 md-pt-80 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-50"
                    subtitleClass="sub-text style-bg"
                    subtitle="Services"
                    titleClass="title"
                    title="We Are Offering All Kinds of IT Solutions Services"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon1}
                            serviceURL="./web-development"
                            Title="Software Development"
                            TextDesc="Get a custom software development solution developed tailored to your requirements to elevate your business profits."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon2}
                            serviceURL="./front-end-development"
                            Title="Web Development"
                            TextDesc="We offer a broad spectrum of web development services to fully tap into the capabilities offered by modern web technologies."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon3}
                            serviceURL="./mobile-app-development"
                            Title="Mobile App Development"
                            TextDesc="End-to-end mobile apps development, from business analysis, design to testing and deployment or online market publication."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon4}
                            serviceURL="./wordpress-development"
                            Title="Open Source Development"
                            TextDesc="We can generate the open source Development solution which you can steer your business website at your own. like wordpress, joomla and drupal etc."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon5}
                            serviceURL="./ux-ui-design"
                            Title="UI/UX Design"
                            TextDesc="Comprehensive UI/UX services that include idea to the wireframes, web & mobile app design, consulting & branding using the latest tools & technologies."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon6}
                            serviceURL="./contact"
                            Title="Software Testing"
                            TextDesc="Quality Assurance (QA) services for Mobile, Website and cloud. Test Automation, Test Process Improvement, Security Testing and Accessibility Testing. "
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                </div>
            </div>
            <div className="shape-animation">
                <div className="shape-part">
                    <img className="dance" src={shapeImg} alt="images" />
                </div>
            </div>
        </div>
    );
}

export default ServiceFlip;