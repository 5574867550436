import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/phpDevelopment/SingleServiceSix';

// Service Image Icons
import serviceImg14 from '../../assets/img/service/style9/ss1.png';
import serviceImg13 from '../../assets/img/service/style9/ss2.png';
import serviceImg11 from '../../assets/img/service/style9/ss3.png';
import serviceImg12 from '../../assets/img/service/style9/ss4.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="Our Expertise"
                    titleClass="title new-title"
                    title="We Serve A Wide Range Of Industries"
                    descClass="desc"
                    description="For our client base & different market segments, we create mobile apps for iOS & Android. With our custom web & mobile app development services, you'll stay on top of the digital evolution."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 md-mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Entrepreneurs"
                            
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 md-mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-pink"
                            serviceImg={serviceImg13}
                            Title="Start-ups"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleServiceSix
                            itemClass="services-item bg-light-blue"
                            serviceImg={serviceImg11}
                            Title="Corporates" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleServiceSix
                            itemClass="services-item bg-light-pink"
                            serviceImg={serviceImg12}
                            Title="Institutions"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

