import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/main-home/softwere-devlop.png';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
import mainIcon48 from '../../assets/img/service/style2/main-img/48.png';
import mainIcon26 from '../../assets/img/service/style2/main-img/26.png';
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-50 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title"
                    title="Why Hire AngularJS Programmers from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="No hidden costs"
                           Text="We do not charge you for anything other than what was quoted, and we keep absolute transparency."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Easy Prescreening"
                           Text="We allow you to prescreen our resources before hiring them to ensure the quality of work."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="03"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Simple Coding"
                            Text="Quality of the code is our priority, and hence we make sure the code is bug-free and well-structured."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIcon48}
                            hoverIcon={mainIcon48}
                            Title="Tech Consultation"
                            Text="Share your project & get a free consultation from technology experts to know more about the technical needs."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon26}
                            hoverIcon={mainIcon26}
                            Title="App Launch"
                           Text="We provide you end-to-end AngularJS development services, including development, design, and deployment."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Clients-centric Approach"
                           Text="We build excellent applications that are well-developed to meet your business needs."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;