import React from "react";
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import SectionTitle from '../Common/SectionTitle';
import ContactUsForm from '../../components/Contact/ContactUsForm';
import Brand from '../Common/Brand';
import {
Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

// WhyChooseUs Images
import mainImg from '../../assets/img/choose/2.png';
import animImg1 from '../../assets/img/choose/dotted-1.png';
import animImg2 from '../../assets/img/choose/dotted-2.png';
import iconOne from '../../assets/img/choose/icons/1.png';
import iconTwo from '../../assets/img/choose/icons/2.png';
import iconThree from '../../assets/img/choose/icons/3.png';
import bannerImg from '../../assets/img/banner/career_img.webp';
import Careersbg from '../../assets/img/careersbg.jpg';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const CareersMainPage = () => {

    return (
        <React.Fragment>

        {/* <!-- banner section start --> */}
            <div  className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 pt-30 pb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="WE ARE HERE TO HELP YOU"
                                titleClass="title pb-25"
                                title="Boost Your Career, Graph With New Heights"
                                descClass="desc pb-16"
                                description="With our help, our clients can achieve amazing things in their own unique way. Our work leaves an indelible impression on the first glance, and offers outstanding results on the second. Take a look behind the scenes at some of our most popular works."
                                secondDescClass="desc pb-16"
                                secondDescription="Become a pro by learning from the best. We can give you a vital boost to your career when you join our Web & App Development Company."
                                
                            />
                        
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
         {/* <!-- banner section end --> */}

         {/* Contact Section Start */}
         <div className="rs-contact rs-why-choose style2 pt-80 pb-80 md-pt-60">
                <div className="container">
                    <SectionTitle
                            sectionClass="sec-title2 text-center mb-60"
                            subtitleClass="sub-text style-bg"
                            subtitle="Let's start the discussion"
                            titleClass="title title"
                            title="Are You Filled With The Passion To Try Fresh, New & innovative Subjects? "
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="Are you an expert in the industry that we have an open position for? We can talk more."
                            
                    />
                    <div className="row bx-shadow">
                        <div className="col-lg-12 col-md-12 pl-30 md-pl-14">
                            <div className="contact-widget">
                                {/* Contact Form */}
                                <ContactUsForm />
                                {/* Contact Form */}
                             </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact Section End */}

        {/* <!-- choose-area-start --> */}
        <div className="rs-faq choose-area pt-80 pb-80">
                <div className="container">
                <SectionTitle 
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg text-center"
                    subtitle="THINK CAREER PROGRESSION"
                    titleClass="title pb-10 text-center"
                    title="We're Hiring!"
                    descClass="desc"
                    description="Ready to start your career with us? Let's create your success story."
                    effectClass="heading-line"
                 />
                <div className="row">
                    <div className="col-xl-12 col-lg-12 mb-30">
                        <div className="choose-wrapper">
                                
                            <div className="faq-box faq-2-box">
                                <Accordion className="accodion-style1" preExpanded={'0'}>
                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                <div className="col-lg-12 col-md-12 pl-0 pr-0">
                                                        <div className="job-detail ree-card">
                                                            <div className="job-title">
                                                                <h4>Front End Developer</h4>
                                                                <p className="mb-10">Vacancies: 2 / Work Status: Full time / Experience: 1 to 3 Years / Qualification: BE, MCA, MSC IT</p>
                                                            </div>
                                                             
                                                        </div> 
                                                    </div> 
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <ul class="job-desc">
                                                    <li>Strong knowledge in HTML5/CSS3/Javascript/jQuery/Responsive Design</li>
                                                    <li>Basic knowledge of UI/UX</li>
                                                    <li>Must have knowledge in Design Framework (e.g. Bootstrap)</li>
                                                    <li>Knowledge of server-side CSS pre-processing platforms, such as LESS and SASS</li>
                                                    <li>Cross browser and multi-platform front-end design implementation</li>
                                                    <li>Problem analysis &amp; solving capabilities with good debugging skills</li>
                                                    <li>Ability to learn new technologies (e.g. Angular, React)</li>
                                                </ul>
                                                <div className="apply-btn mt-15 pb-2">
                                                    <a href="./contact" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                <div className="col-lg-12 col-md-12 pl-0 pr-0">
                                                        <div className="job-detail ree-card">
                                                            <div className="job-title">
                                                                <h4>PHP Developer</h4>
                                                                <p className="mb-10">Vacancies: 2 / Work Status: Full time / Experience: 1 to 5 Years / Qualification: BE, MCA, MSC IT</p>
                                                            </div>
                                                            {/* <div className="apply-btn pt-2 pb-2">
                                                                <a href="#" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                            </div>  */}
                                                        </div> 
                                                    </div> 
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <ul class="job-desc">
                                                    <li>Strong knowledge of PHP Web Frameworks (Yii/Yii2, Zend, Laravel, CI)</li>
                                                    <li>Integrated payment gateways and good with APIs</li>
                                                    <li>Demonstrable knowledge of web technologies (HTML, CSS, Javascript, Ajax)</li>
                                                    <li>Experience in common third party APIs (Google, Facebook, eBay etc.)</li>
                                                    <li>Passion for best design and coding practices</li>
                                                    <li>Ability to take up R&amp;D activities and resolve issues</li>
                                                </ul>
                                                <div className="apply-btn mt-15 pb-2">
                                                    <a href="./contact" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                <div className="col-lg-12 col-md-12 pl-0 pr-0">
                                                        <div className="job-detail ree-card">
                                                            <div className="job-title">
                                                                <h4>QA</h4>
                                                                <p className="mb-10">Vacancies: 2 / Work Status: Full time / Experience: 2+ Years / Qualification: BE, MCA, MSC IT</p>
                                                            </div>
                                                            {/* <div className="apply-btn pt-2 pb-2">
                                                                <a href="#" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                            </div>  */}
                                                        </div> 
                                                    </div> 
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <ul class="job-desc">
                                                    <li>Review requirements, specifications and technical design documents to provide timely and meaningful feedback</li>
                                                    <li>Create detailed, comprehensive and well-structured test plans and test cases</li>
                                                    <li>Estimate, plan and coordinate testing activities</li>
                                                    <li>Identify, record, document thoroughly and track bugs</li>
                                                    <li>Perform thorough regression testing when bugs are resolved</li>
                                                    <li>Develop and apply testing processes for new and existing softwares /websites /mobile application to meet client needs</li>
                                                    <li>Monitor debugging process results</li>
                                                    <li>Track quality assurance metrics, like defect densities and open defect counts</li>
                                                    <li>Stay up-to-date with new testing strategies</li>
                                                </ul>
                                                <div className="apply-btn mt-15 pb-2">
                                                    <a href="./contact" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                <div className="col-lg-12 col-md-12 pl-0 pr-0">
                                                        <div className="job-detail ree-card">
                                                            <div className="job-title">
                                                                <h4>React Native Developer</h4>
                                                                <p className="mb-10">Vacancies: 2 / Work Status: Full time / Experience: 1 to 3 Years / Qualification: BE, MCA, MSC IT</p>
                                                            </div>
                                                            {/* <div className="apply-btn pt-2 pb-2">
                                                                <a href="#" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                            </div>  */}
                                                        </div> 
                                                    </div> 
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <ul class="job-desc">
                                                    <li class="">Ability to communicate in English fluently and idiomatically.</li>
                                                    <li class="">Must know about the state management, Redux, MobX etc.</li>
                                                    <li class="">Must require knowledge of typescript.</li>
                                                    <li class="">Must require knowledge of rect hooks and able to write and use functional components.</li>
                                                    <li class="">Aware about the animation in React Native.</li>
                                                    <li class="">Experience with CI/CD will be an advantage.</li>
                                                    <li class="">Proficient understanding of code versioning tools, such as Git.</li>
                                                    <li class="">Ability to understand business requirements and translate them into technical requirements.</li>
                                                    <li class="">Design, performance optimization, reduce boilerplate code and write code that works consistently across platforms.</li>
                                                </ul>
                                                <div className="apply-btn mt-15 pb-2">
                                                    <a href="./contact" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                <div className="col-lg-12 col-md-12 pl-0 pr-0">
                                                        <div className="job-detail ree-card">
                                                            <div className="job-title">
                                                                <h4>Mean Stack/ Mern Stack Developer</h4>
                                                                <p className="mb-10">Vacancies: 1 / Work Status: Full time / Experience: 1 to 5 Years / Qualification: BE, MCA, MSC IT</p>
                                                            </div>
                                                            {/* <div className="apply-btn pt-2 pb-2">
                                                                <a href="#" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                            </div>  */}
                                                        </div> 
                                                    </div> 
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <ul class="job-desc">
                                                    <li>Experience in Angular, NodeJS, Express, MongoDB, Reactjs</li>
                                                    <li>Handson experience in JavaScript on both client and server-side</li>
                                                    <li>Creating secure RESTful-based web services in XML and JSON, Javascript, JQuery</li>
                                                    <li>Experience with modern frameworks and design patterns</li>
                                                    <li>Pro-active, Passion with zeal for best practices</li>
                                                    <li>Strong logic and problem solving skills</li>
                                                    <li>A Plus to have experience in Continuous integration & version control (SVN, Git)</li>
                                                </ul>
                                                <div className="apply-btn mt-15 pb-2">
                                                    <a href="./contact" class="readon buy-now get-in" target="_blank"style={{top:0}}>Apply Now</a>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        {/* <!-- choose-area-end --> */}

        <div className="rs-call-us relative pt-80 mb-40">
            <div className="container">
                <div className="row">
                <div className="col-lg-1"></div>
                    <div className="col-lg-10 pb-30 md-pb-15">
                        <div className="rs-contact-box text-center">
                             <SectionTitle
                                sectionClass="sec-title3 text-center"
                                subtitleClass="sub-text style-bg"
                                subtitle="CALL US 24/7"
                                titleClass="title "
                                title="Boosting Growth With a Trusted Tech Partner!"
                                descClass="desc"
                                description="Get assistance from the top IT solution provider company & turn your project idea into reality. Our representative will reply you shortly."
                            />
                            
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div class="job-apply-form col-lg-12">
                        <ul>
                            <li><a href="mailto:career@metaqualt.com"><i class="fa fa-envelope-open"></i> career@metaqualt.com</a></li>
                            <li><a href="tel:(+91) 999–5554"><i class="fa fa-phone"></i> (+91) 999–5554</a></li>
                        </ul>
                    </div>
                </div>
            </div>
           
        </div>
            {/* Contact Part Start */}
            {/* <div className="rs-contact style2 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        subtitleClass="sub-text style-bg con-bg white-color"
                        subtitle="Contact"
                        titleClass="title testi-title white-color"
                        title="Apply Now"
                        effectClass="heading-line"
                    />
                    <div className="row bx-shadow">
                        <div className="contact-box-wrap">
                            <div className="contact-widget"> */}
                                {/* Contact Form */}
                                {/* <ContactUsForm /> */}
                                {/* Contact Form */}
                            {/* </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Contact Part End */}

           
            <div className="rs-why-choose style1 pt-80 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 hidden-md">
                            <div className="image-part">
                                <img
                                    src={mainImg}
                                    alt="Main Image"
                                />
                            </div>
                            <div className="animation style2 modify-style">
                                <div className="top-shape">
                                    <img
                                        className="dance"
                                        src={animImg2}
                                        alt="Animation Two"
                                    />
                                </div>
                                <div className="bottom-shape">
                                    <img
                                        className="dance2"
                                        src={animImg1}
                                        alt="Animation One"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 pl-60 md-pl-14">
                                <SectionTitle
                                    sectionClass="sec-title2 mb-30"
                                    titleClass="title title"
                                    title="Hire Dedicated Web Designer at your flexibility."
                                    descClass="desc"
                                    description="Working with us directly, you can take advantage of the benefits of UI Developer on hire available to several IT companies in abroad."
                                />
                            <div className="services-wrap mb-24">
                                <div className="services-icon">
                                    <img
                                        src={iconOne}
                                        alt="Choose Icon"
                                    />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><a href="#">Full Time</a></h3>
                                    <p className="services-txt">  9 hours/Day for Min 30 Days contract. Monthly Payment cycle.</p>
                                </div>
                            </div>
                            <div className="services-wrap mb-24">
                                <div className="services-icon">
                                    <img
                                        src={iconTwo}
                                        alt="Choose Icon"
                                    />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><a href="#">Part Time</a></h3>
                                    <p className="services-txt">  4 hours/Day for Min 30 Days contract. Weekly Payment cycle.</p>
                                </div>
                            </div>
                            <div className="services-wrap">
                                <div className="services-icon">
                                    <img
                                        src={iconThree}
                                        alt="Choose Icon"
                                    />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><a href="#">Hourly</a></h3>
                                    <p className="services-txt">  Flexible hours/Day for Min 50 Hours contract. daily payment cycle.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <AccordionDefault />   */}
            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}
        </React.Fragment>
    )
}

export default CareersMainPage;





