import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/sf-development.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import WhyChoose from '../php-development/whychooseus';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import SS1 from '../../assets/img/banner/SS1.png';
import SS2 from '../../assets/img/banner/SS2.png';
import SS3 from '../../assets/img/banner/SS3.png';
import SS4 from '../../assets/img/banner/SS4.png';


// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon24 from '../../assets/img/service/style2/main-img/24.png';
import serviceIcon25 from '../../assets/img/service/style2/main-img/25.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

// Service Icons
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const AngularJsDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center pt-70">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Full Stack JavaScript Development Solutions"
                                titleClass="title pb-25"
                                title="Human-Centered Front-End Development Services That Utilize Visual Polish"
                                descClass="desc pb-5"
                                description="With our frontend developers' extensive experience across a variety of industries, they understand frontend technologies in-depth. Providing best-in-class frontend design and user interaction is something we have a proven track record of doing."
                                secondDescClass="desc pb-16"
                                secondDescription="With the rapidly updating frontend technologies, we maintain great expertise in frontend development technologies. A front-end team specializing in Javascript frameworks like Angular, Reactjs, and Vuejs. We have already developed single-page applications, UI/UX of large-scale apps, progressive web apps, HTML5, CSS, and Javascript user interfaces."
                            />
                        </div>
                       
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-60 pb-60">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="Extensive Front-End Development Capabilities "
                        descClass="desc"
                        description="We offer professional front-end development teams equipped with cutting-edge tech expertise to craft engaging, innovative, and responsive business applications"
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-20">
                                <div className="text-left pb-15">
                                    <img className="main" src={SS1} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-28"
                                        title="FrontEnd Architecture & Design"
                                        descClass="desc"
                                        description="Create eye-catching and responsive user experiences using the best front-end technologies. We deliver solutions that complement your goals by combining your vision & expertise." 
                                    />
                                </div>
                            </div>
                            
                            <div className="col-lg-6 mb-20">
                                <div className="text-left pb-15">
                                    <img className="main" src={SS2} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-28"
                                        title="Developing FrontEnd User Interfaces"
                                        descClass="desc"
                                        description="Improve your digital presence by creating user-interfaces that simplify the search process, make even complex content readable and easily accessible, and enhance engagement."  
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 mb-20">
                                <div className="text-left pb-15">
                                    <img className="main" src={SS4} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-28"
                                        title="Custom FrontEnd Development"
                                        descClass="desc"
                                        description="As a team of dedicated front-end developers, our developers develop solutions tailored to the needs of a wide range of industries."  
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 mb-20">
                                <div className="text-left pb-15">
                                    <img className="main" src={SS3} alt=""/>
                                </div>
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-28"
                                        title="Maintenance and Support"
                                        descClass="desc"
                                        description="Your applications are supported & maintained by a team of experts. We can deploy technicians with the latest trends & be able to troubleshoot all issues."  
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-end */}

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-60"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title"
                        title="Why Choose Us for Development?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Proven Expertise"
                            Text="Experience developing user-centric web applications using JavaScript, resulting in unrivaled user-experiences."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Customer-Centric" 
                            Text="We maintain our clients' websites consistently and proactively after their websites have been developed." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon24}
                            Title="Year-long Experience" 
                            Text="We have years of experience developing feature-rich JavaScript applications in the blink of an eye." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon25}
                            Title="Result-Drive" 
                            Text="We provide customized solutions tailored to your industry's individual needs, giving you a competitive edge." 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
             {/* rs-services-area-start */}
             <WhyChoose /> 
            {/* rs-services-area-start */} 

            {/* rs-servicess-area-start */}
            <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text  style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated Front-End Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default AngularJsDevelopment;