import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/sf-design.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import SingleServiceFour from '../../components/Codeigniter/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import WhyChoose from '../php-development/whychooseus';

import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain40 from '../../assets/img/service/style2/main-img/40.png';
import imgMain41 from '../../assets/img/service/style2/main-img/41.png';
import imgMain42 from '../../assets/img/service/style2/main-img/42.png';
import imgMain43 from '../../assets/img/service/style2/main-img/43.png';
import imgMain28 from '../../assets/img/service/style2/main-img/28.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';

import serviceM1 from '../../assets/img/service/M1.png';
import serviceM2 from '../../assets/img/service/M2.png';
import serviceM3 from '../../assets/img/service/M3.png';
import serviceM4 from '../../assets/img/service/M4.png';
import serviceM5 from '../../assets/img/service/M5.png';
import serviceM6 from '../../assets/img/service/M6.png';
import serviceM7 from '../../assets/img/service/M7.png';
import serviceM8 from '../../assets/img/service/M8.png';
import serviceM9 from '../../assets/img/service/M9.png';
import serviceM10 from '../../assets/img/service/M10.png';
import serviceM11 from '../../assets/img/service/M11.png';
import serviceM12 from '../../assets/img/service/M12.png';
// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import techImg34 from '../../assets/img/technology/style2/34.svg';
import techImg35 from '../../assets/img/technology/style2/35.svg';
import techImg36 from '../../assets/img/technology/style2/36.svg';
import techImg37 from '../../assets/img/technology/style2/37.svg';
import techImg38 from '../../assets/img/technology/style2/38.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const HTML5CSS3 = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center pt-50 pb-30">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="UI/UX Web Design Agency For Your Business"
                                descClass="desc pb-5"
                                description="Creating Dazzling Creative Web Designs For Rewarding Returns. Website and web app development focuses on user interaction and user experience. If you do not present your product creatively, you will never engage users even if your product is good.  In order for a website to appear engaging and attractive, its UI/UX design is extremely important. In order to create a visually appealing website, UI/UX are crucial.  "
                                secondDescClass="desc pb-10"
                                secondDescription="Our creative, user-centered UI/UX designs have helped many businesses create effective websites. Our website design services are among the most affordable in the industry, with easy-to-use interfaces that encourage your clients to act. Both small & large companies benefit from the expertise of our UI/UX development team in designing feature-rich, immersive websites."
                            />
                        </div>
                    </div>
                    <div className="row pt-50">
                        <div className="col-lg-12 col-md-12 pt-25 text-center">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                titleClass="title pb-25 w-80 fz-36"
                                title="Brands Will Reach New Heights With Our Goals Blended With Colors"
                                descClass="desc pb-5"
                                description="The Pretty websites we have in mind can open up a very wide range of doors for businesses and users. As a result, we helped shape powerful applications for the world's most innovative companies by impeding infamous UI/UX. Metaqualt is a leading UX/UI web development & design company with a dedicated focus on connected objects. As part of its services, it assists its clients in creating intuitive and attractive interfaces and optimizing their services. Over the past ten years, we have provided UI/UX design and development services to both small and large businesses. UI/UX Design has always been at the center of the company's client support approach since its inception."
                            />
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-services-area-start */}
            <div className="rs-technology pt-30 pb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1 md-hidden"></div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg34} alt="" />
                                    </div>
                               
                            </div>
                        </div>  
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg35} alt="" />
                                    </div>
                               
                            </div>
                        </div>  
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg36} alt="" />
                                    </div>
                               
                            </div>
                        </div> 
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg37} alt="" />
                                    </div>
                               
                            </div>
                        </div> 
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg38} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 col-12"></div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg8} alt="" />
                                    </div>
                               
                            </div>
                        </div> 
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg9} alt="" />
                                    </div>
                               
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg4} alt="" />
                                    </div>
                               
                            </div>
                        </div>   
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                               
                                    <div className="logo-img">
                                        <img src={techImg3} alt="" />
                                    </div>
                               
                            </div>
                        </div>                                  
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}


            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="All-Round UI/UX Design Services"
                        descClass="desc"
                        description="By utilizing our UI/UX Design services, we get design that defines goals, is easy to use, is cross-platform compatible, and has consistency across all platforms. Make your brand stand out by hiring an UI/UX designer."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain40}
                                    HoverImg={imgMain40}
                                    Title="Web Interface Design"
                                    Text="We create web design services based on extensive research and with utmost dedication. Our designs are well organized with a well-defined user flow & they are eye-catching."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain41}
                                    HoverImg={imgMain41}
                                    Title="Responsive Design Development"
                                    Text="When you choose our responsive web design services, you don't have to spend extra for each platform, and your site works across a variety of devices."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain42}
                                    HoverImg={imgMain42}
                                    Title="Wireframes & Prototypes"
                                    Text="Prototypes and wireframes are used to test the design style 2 and framework before investing time on details."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="Design Layout To HTML"
                                    Text="Whether you have an existing design or one that fits your brand value, we have someone who can transform it into an HTML document that is flawless."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain43}
                                    HoverImg={imgMain43}
                                    Title="Custom Logo Design & Branding"
                                    Text="The services we provide include the creation of brand identity, brand name, brand marketing & brand identity to make a huge impact on your potential customers."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="With UI/UX Design Services, You Can Bridge The Gap Between Real and Digital."
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}         

             {/* rs-servicess-area-start */}
             <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Design Services"
                        titleClass="title"
                        title="Next-Gen UX/UI Design Beside Professional Excellence"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                   
                        <SingleServiceFour
                            serviceIcon={serviceM1}
                            Title="Feature-Rich And Advanced Web Design"
                            
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM2}
                            Title="Landing Page Redesign & Optimization" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM3}
                            Title="HTML Prototyping & Wireframe Prototyping" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM4}
                            Title="MVP & Product Prototype Design " 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM5}
                            Title="PSD to Bootstrap & Wordpress conversion " 
                           
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM6}
                            Title="PSD Slicing / HTML Conversion" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM7}
                            Title="PSD to XHTML/CSS2F Conversion"
                            
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM8}
                            Title="Easy CMS or Ecommerce Integration "
                            
                        />

                        <SingleServiceFour
                            serviceIcon={serviceM9}
                            Title="Interface Design" 
                           
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM10}
                            Title="Rapid Prototyping" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM11}
                            Title="Responsive Designs"
                            
                        />
                        <SingleServiceFour
                            serviceIcon={serviceM12}
                            Title="Mobile UI Designing "
                            
                        />
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 


            {/* rs-services-area-start */}
            <WhyChoose /> 
            {/* rs-services-area-start */}           

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-46"
                            subtitleClass="sub-text style-bg"
                            subtitle="Process"
                            titleClass="title title"
                            title="We Make UI/UX Design By Our Crafting Expertise"
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="The design process is everything that affects the user's experience as a leader in UI/UX design. UX/UI experts create user interfaces to boost conversion, provide a consistent user experience on mobile apps and websites."  
                        />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Pixel-by-pixel, img-by-img"
                                Text="In order to give you the power of best look and feel, we work continuously and analyze future design time."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="User-Centered Design" 
                                Text="It has been our practice to blend the core principles of design with usability & functionality while keeping the user in the center." 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon15}
                                Title="Future-Centric Designs" 
                                Text="Our dedicated designers create logical and technical art that appeals to the heart of the user, not just an art piece." 
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
             {/* rs-servicess-area-start */}
             <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
				ctaTitleClass="epx-title"
				ctaTitle="Hire The Dedicated Web Designer"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default HTML5CSS3;