import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon90 from '../../assets/img/service/style2/main-img/90.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-80 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Solution"
                    titleClass="title testi-title"
                    title="Choosing Us for Real Estate Business App"
                    descClass="desc"
                    description="Having worked with a variety of clients, we have helped them maximize their revenue and profits by developing real estate mobile apps. In the mobile app development industry, we have gained enough experience and knowledge over the years. Taking advantage of our Real Estate Development Solutions can assist you in achieving the finest results and managing your business successfully."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon2}
                            hoverIcon={mainIcon2}
                            Title="One-Stop Destination"
                            serviceURL="#"
                            Text="Being a one-stop solution for mobile app development services, we develop future-ready apps."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Expert professionals"
                            serviceURL="#"
                           Text="We are backed up with a team of skilled experts to make sure you get the best solutions."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Procedure"
                            Text="Using the Agile methodology, we conduct frequent scrum events to optimize practices and methods."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon90}
                            hoverIcon={mainIcon90}
                            Title="Round-The-Clock Support"
                           Text="We provide round-the-clock support to our clients so that your app is up all the time."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Cost-effectiveness"
                            Text="You can be assured that you would get cost-effective mobile app development services."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Quick Delivery"
                            Text="Our company is known for delivering mobile apps on time, and within your budget."
                        />
                    </div>
                </div>
            </div>
           
        </div>
    );
}

export default WhyChoose;