import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-80 pb-50">
           <div className="container relative">
                    <SectionTitle
                            sectionClass="sec-title2 text-center mb-40"
                            subtitleClass="sub-text style-bg"
                            subtitle="We are working with"
                            titleClass="title pb-10"
                            title="Our ReactJS Development Services"
                            descClass="desc mb-25"
                            description="ReactJS is on the verge of overpowering every other technology in the domain and that is because of the brands such as Facebook and Google placing its trust in it. Hire the experts that can help you get placed amongst the leaders in the industry."
                            effectClass="heading-line"
                    />
                        <div className="row align-items-center">
                        <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Cross-platform app development"
                                 />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="ReactJS Migration & Integration"
                                 />
                            </div>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Support and Migration Services"
                                />
                            </div>
                            </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="React JS Plugin Development"
                                 />
                            </div>
                       
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="ReactJS UI development"
                                />
                            </div>
                            
                            <div className="col-md-12 mb-30">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="QA & Testing Services"
                                 />
                            </div>
                            </div>
                    </div>   
                     
                </div>
            </div>
        </div>
    );
}

export default OurServices;