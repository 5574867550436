import React, { Component } from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ContactMain from './ContactMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';

const Contact = () => {
    return (
        <React.Fragment>
            {/* Header */}
            <HeaderStyleThree
                parentMenu='contact'
                activeMenu="/contact"
            />
            {/* Header */}

             {/* breadcrumb-area-start */}
             <SiteBreadcrumb
                    pageTitle="Contact Us "
                    titleClass="page-title"
                    pageName="Contact Us"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Get in Touch"
            />
            {/* breadcrumb-area-start */}


            {/* ContactMain Section Start */}
            <ContactMain />
            {/* ContactMain Section End */}

            {/* Footer */}
            <Footer />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}

            <ScrollToTop />
        </React.Fragment>

    );
}


export default Contact;