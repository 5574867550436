import React from 'react';
import SingleServiceThree from '../../components/Service/SingleServiceThree';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

import serviceImg100 from '../../assets/img/service/style2/main-img/100.png';
import serviceImg48 from '../../assets/img/service/style2/main-img/48.png';
import serviceImg65 from '../../assets/img/service/style2/main-img/65.png';

const AboutSlider = () => {
   
    return (
        <React.Fragment>

        <div  className="rs-businessmodels style3 pt-80 pb-20 md-pt-80 md-pb-20">
           
           <div className="container relative">
                    <SectionTitle
                       sectionClass="sec-title2 text-center mb-44 md-mb-30"
                       subtitleClass="sub-text style-bg"
                       subtitle="Our services are at the forefront"
                       titleClass="title"
                       title="Engagement Models"
                        descClass="desc w-80"
                        description="Businesses have unique needs and are looking for solutions that cater to their needs. We understand that each company is unique and needs flexibility and solutions tailored to meet their needs. Our engagement models are well-defined so that together, we can maximize the benefit. We know what is best for your business."
                        effectClass="heading-line"
                     />
               <div className="row align-items-center">

                    <div className="col-lg-12 col-md-12">
                       <div className="row">
                           <div className="col-lg-4 col-md-6 mb-40">
                               <SingleServiceThree 
                                   itemClass="rs-businessModels"
                                   serviceImage={serviceImg100}
                                   Title="Build Your Team"
                                   Text="Our cross-functional teams can fully convert your business ideas to reality. Agile team at its best!"
                               />
                           </div>
                           <div className="col-lg-4 col-md-6 mb-40">
                               <SingleServiceThree 
                                   itemClass="rs-businessModels"
                                   serviceImage={serviceImg48}
                                   Title="Dedicated Talent "
                                   Text="We will work exclusively on your projects, according to your requirements. You are in control of your software solutions."
                               />
                           </div>
                           <div className="col-lg-4 col-md-6 mb-40">
                               <SingleServiceThree 
                                   itemClass="rs-businessModels"
                                   serviceImage={serviceImg65}
                                   Title="Fixed Cost Projects"
                                   Text="Manage your budget with our fixed cost model. Ideal for creating proofs-of-concept or executing your unique ideas."
                               />
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
        </React.Fragment>
    )
}

export default AboutSlider