import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-80 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title"
                    title="Why Hire NodeJS Programmers from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Reduced Development Cost"
                           
                            Text="Our PHP development can reduce your development cost to about 40%. It is cost-effective than hiring in-house resources."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Fill The Skill Gap"
                            
                            Text="Connect with dedicated PHP developers with more than 5+ years of experience in advanced PHP frameworks, & fill the skill gap required to fulfill your project needs."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Ensure Operation Agility"
                           
                            Text="We help you in creating a technology ecosystem and give you all the necessary tools for building your business stronger."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Faster time to market"
                            
                           Text="We have an incredibly fast turnaround time; we use advanced technology, tools, and proven development practices to speed up the development process."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Choose from Large Pool"
                           
                            Text="Hire PHP developers from one of the largest PHP developers’ pools. We have the industry’s most talented and seasoned developers with in-depth industry knowledge."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Future Proof Websites"
                           
                           Text="Find PHP developers for hire with unprecedented knowledge & experience in developing modern solutions with the inclusion of technology such as AI, ML, Chatbots, AR/VR, etc."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;