import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png';
import mainIcon5 from '../../assets/img/service/style2/main-img/5.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon100 from '../../assets/img/service/style2/main-img/100.png';
import mainIcon14 from '../../assets/img/service/style2/main-img/14.png';
import mainIcon24 from '../../assets/img/service/style2/main-img/24.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-50 pb-50">
            <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center pt-30 pb-10"
                        subtitleClass="sub-text style-bg"
                        subtitle="Why Us"
                        titleClass="title testi-title text-white"
                        title="How We Make A Difference"
                        descClass="desc w-80"
                        description="As a client-centric company, we are focused on having lean, transparent, and effective processes. Bringing tangible results for our clients is something that we take great pride in due to the quality of our work and our proactive communication."
                        effectClass="heading-line"
                    />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Client-Centric Approach"
                            serviceURL="software-development"
                            Text="Client-centricity makes our true strengths and unbeatable development services visible to our clients."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon2}
                            hoverIcon={mainIcon2}
                            Title="One-stop Solution"
                            serviceURL="web-development"
                           Text="Our goal is to be your one-stop solution for all your real-world development issues. Digital solutions are our expertise."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="We brainstorm a lot!"
                            serviceURL="analytic-solutions"
                            Text="Brainstorming leads to new ideas & thoughts. Discussing & bridging the gap leads to better suggestions & applications."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item purple-bg"
                            prefix="05"
                            mainIcon={mainIcon5}
                            hoverIcon={mainIcon5}
                            Title="Maximum Velocity"
                            serviceURL="product-&-design"
                            Text="Our technically balanced app development process lets us help our clients with the maximum velocity."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item green-bg"
                            prefix="06"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Value for Money!"
                            serviceURL="database-administrator"
                            Text="We serves you the best quote for your application & web services, we use a modular approach. No hidden costs."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item paste-bg"
                            prefix="04"
                            mainIcon={mainIcon4}
                            hoverIcon={mainIcon4}
                            Title="Believe in Innovation!"
                            serviceURL="clould-&-devOps"
                            Text="Innovative ideas are like Jugaad. If you try it, you'll find out. Innovative ideas help you stand out & lead the competition."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="07"
                            mainIcon={mainIcon100}
                            hoverIcon={mainIcon100}
                            Title="Team Benefits"
                            Text="Our creative designer collaborates with our expert developers to deliver unexpected development results for your requested app development."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="08"
                            mainIcon={mainIcon14}
                            hoverIcon={mainIcon14}
                            Title="Quality Service"
                            Text="The only thing in the mind of us always is - to build & deliver the best. We have been Delivering the best app development services & our client's happy faces are the proof."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="09"
                            mainIcon={mainIcon24}
                            hoverIcon={mainIcon24}
                            Title="Economical Solutions"
                            Text="We make sure that our revered clientele receives our end-to-end software development services at affordable and reasonable charges. Our unsung quality: You only have to pay what’s worth it."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;