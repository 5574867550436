import React from 'react';
import { Link } from 'react-router-dom';
import TypeEffect from './TypeEffect';
const BannerTwo = () => {

    return (
        <React.Fragment>

            {/* <!-- banner section start --> */}
            <div className="rs-banner style2 pt-330 pb-250 md-pt-250 md-pb-100 sm-pt-230 sm-pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12">
                            <div className="banner-content">
                                <div className="bnr-subtitle style-bg">DEDICATED TO INNOVATION</div>
                                <h1 className="bnr-title">We Are Innovators In Terms of </h1>

                                <TypeEffect />
                                
                                <p className="bnr-titlesmall">Securing the position in the top app development companies list, We help Startups, Entrepreneurs & Enterprises -shaping their ideas into award-winning products!</p>
                                
                                <div className="btn-part">
                                    <Link className="readon buy-now get-in" to="./contact">Get In Touch</Link>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}

        </React.Fragment>
    );
}

export default BannerTwo;