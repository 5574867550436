import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/sf-development.webp';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import WhyChoose from './whychooseus';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain16 from '../../assets/img/service/style2/main-img/16.png';
import imgMain21 from '../../assets/img/service/style2/main-img/21.png';
import imgMain20 from '../../assets/img/service/style2/main-img/20.png';
import imgMain17 from '../../assets/img/service/style2/main-img/17.png';
import imgMain23 from '../../assets/img/service/style2/main-img/23.png';
import imgMain22 from '../../assets/img/service/style2/main-img/22.png';
// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
import valueBg from'../../assets/img/banner/light-bg.svg';

const phpDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* banner section-start */}    
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="You Dream It, We Make It Happen!"
                                titleClass="title pb-25"
                                title="Nothing But The Best With PHP Development Services"
                                descClass="desc pb-5"
                                description="The PHP programming language is regarded as the most popular and time honoured web programming language. Because of its reliability, scalability, lightweight code, and ease of deployment, it has remained popular for a long time. In today's world, businesses can't compete with a slow developer and a cramped PHP team. The frontend of your digital presence will not be enough to capture the hearts of your target audience if you don't have a strong backend. A dedicated PHP Developer team is essential for businesses wishing to grow quickly. We are confident that we are one of the best PHP Development Companies able to build winning websites for clients. As you, we have the experience to support your dream."
                            />
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    <div className="row pt-50">
                        <div className="col-lg-12 col-md-12 pt-25 text-center">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                titleClass="title pb-25 w-80 fz-36"
                                title="Ensure the Best Practices with Speed & Performance with PHP Web Development "
                                descClass="desc pb-5"
                                description="We improve the website's extensibility and make it simpler to learn and organize with the help of our creative minds. Your organization will eventually grow rapidly as a result. Our developers are skilled enough to create everything from simple PHP-based websites and apps to modern domain-specific solutions. We have appeared as a leading PHP Web Application Development Services provider in the region because of our exceptional PHP development services and our conversation methods."
                            />
                        </div>
                    </div>

                    {/* counter area start */}
                    <CounterHomeFive />
                    {/* counter area end */}
                </div>
            </div>
            {/* banner section-area-end */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-50 pb-50" style={{
                backgroundImage: 'url('+valueBg+')',
                backgroundSize: "cover",
                backgroundPosition:"top left;"
                }}>
                    <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-30"
                            subtitleClass="sub-text style-bg"
                            subtitle="Our Expertise"
                            titleClass="title pb-10"
                            title="Our PHP Development Services"
                            descClass="desc"
                            description="We ensure that client deliveries align with business needs with the extensive experience of our development team. Your built product needs to be intuitive, usable, and high-engagement with customers."
                            effectClass="heading-line"
                        />
                    
                    <div className="rs-services style3 modify2 pt-20 md-pt-50">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <SingleServiceFive
                                        itemClass="services-item light-purple-bg"
                                        MainImg={imgMain16}
                                        HoverImg={imgMain16}
                                        Title="Custom PHP Web Development"
                                        Text="We have expertise in custom website development that will sync up with your business esteem according to your tailored needs."
                                        btnClass='d-none'
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <SingleServiceFive
                                        itemClass="services-item light-purple-bg"
                                        MainImg={imgMain17}
                                        HoverImg={imgMain17}
                                        Title="CMS Development"
                                        Text="Our PHP content management systems can be used for a variety of websites. We develop custom CMSs utilizing our PHP expertise."
                                        btnClass='d-none'
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <SingleServiceFive
                                        itemClass="services-item light-purple-bg"
                                        MainImg={imgMain21}
                                        HoverImg={imgMain21}
                                        Title="Portal Development Solutions"
                                        Text="Get business-oriented web portals using PHP such as B2B, B2C, enterprise, corporate portals, and multi-tenant web platforms."
                                        btnClass='d-none'
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <SingleServiceFive
                                        itemClass="services-item light-purple-bg"
                                        MainImg={imgMain22}
                                        HoverImg={imgMain22}
                                        Title="Maintenance & Support"
                                        Text=" Our PHP web clients receive continuous and dedicated ongoing support that helps them to keep their website up-to-date with value additions."
                                        btnClass='d-none'
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <SingleServiceFive
                                        itemClass="services-item light-purple-bg"
                                        MainImg={imgMain22}
                                        HoverImg={imgMain22}
                                        Title="CRM Development"
                                        Text="We offer domain-specific CRM solutions for multiple industries to boost sales and enhance marketing and customer relation abilities."
                                        btnClass='d-none'
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <SingleServiceFive
                                        itemClass="services-item light-purple-bg"
                                        MainImg={imgMain23}
                                        HoverImg={imgMain23}
                                        Title="API Development"
                                        Text="We develop & integrate different APIs (public, private, third-party) into your PHP system & CMS using multiple interfaces equipped by the APIs."
                                        btnClass='d-none'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            {/* rs-services-area-end */}
            
            {/* rs-services-area-start */}
            <Technologies />          
             {/* rs-services-area-end */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50 md-pt-80 md-pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-46"
                            subtitleClass="sub-text style-bg"
                            subtitle="Process"
                            titleClass="title title"
                            title="Why Choose Us for Development?"
                            effectClass="heading-line" 
                            descClass="desc w-80"
                        />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Proven Expertise"
                                Text="To ensure optimum platform-specific native user experience, our expert Dart programmers hand-code many parts of the app."
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="Customer-Centric" 
                                Text="After the development of our clients' websites has been completed, we continue to support the sites consistently and proactively." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon15}
                                Title="Competitive Rate" 
                                Text="With our expertise in PHP web development, we can cater to all types of challenging web projects across all niches at the most competitive price point." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon3}
                                Title="Dedicated Team" 
                                Text="With an experienced PHP team and a track record of successfully completing many PHP web projects, we boast a large pool of PHP programmers." 
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-end */}
            
             {/* rs-servicess-area-start */}
            <WhyChoose />
             {/* rs-servicess-area-end */}

            {/* rs-servicess-area-start */}
            <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div>
            {/* rs-services-area-end */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire The Dedicated PHP Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
            {/* working-process-area-start */}
             <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row works_process">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default phpDevelopment;