import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-50 pb-50">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="What We Provide"
                        effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Daily Reporting Via Email"
                                    Text="Our project managers are efficient in handling projects of different sizes, types & provide you daily updates."
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="160 Man Hours Guaranteed"
                                    Text="With a monthly rolling contract, you can hire dedicated resources for 160 hours solely devoted to your project."
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Flexible Hours Timezone"
                                    Text="To provide you with the best possible communication, feedback, changes, and more, we have managed the time zones well."
                                />
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-5 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Monthly/Yearly Hiring"
                                    Text="Depending on your requirements, hire dedicated resources on a monthly or yearly basis to fit your project's needs."
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Daily/Weekly Code Delivery"
                                    Text="Check your products live, not only through reports, with us, and receive code delivery daily and weekly."
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Direct Access Of Resources"
                                    Text="A wide range of industry resources at your fingertips. For utmost transparency, contact them directly via Skype, email, phone, or chat."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div>
                </div>
            </div>
        </div>
    );
}

export default OurServices;