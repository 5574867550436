import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-80 pb-50 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text style-bg"
                    subtitle="Solution"
                    titleClass="title testi-title"
                    title="Why Choose Us For Wellness & Fitness App Development?"
                    descClass="desc"
                    description="With our expertise in wellness and fitness software development, we assist modern businesses in effectively serving their diverse needs.. Our main goal is to provide clients with high-quality services at an affordable cost. Our dedication to providing you with the best assistance is what makes us the best choice once you assign us your project requirements. Our team creates scalable and interactive applications for dieticians, personal trainers, gym owners, yoga instructors, etc. Our fitness and wellness applications are the best option whenever it comes to building them:"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Customer Satisfaction"
                            serviceURL="#"
                            Text="As per your requirements, we deliver user-friendly, scalable, secure & new business solutions that are user-friendly, scalable & secure."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Flexible Engagement Models"
                            serviceURL="#"
                           Text="By offering flexibility in engagement and hiring models, we don't force clients to work under rigid and strict parameters."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Integrity & Transparency"
                            serviceURL="#"
                            Text="Our team respects your ideas & vision, provides you with every detail of your project regularly & considers your valuable advice as needed."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Dedicated Teams"
                            serviceURL="#"
                            Text="Having a dedicated team of developers able to provide high-end business solutions on different platforms."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Procedure"
                            serviceURL="#"
                            Text="Using the Agile methodology, we conduct frequent scrum events to optimize practices and methods."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Competitive Rates"
                            serviceURL="#"
                            Text="With our services, you can be rest assured that we combine competitive pricing with outstanding quality."
                        />
                    </div>
                </div>
            </div>
           
        </div>
    );
}

export default WhyChoose;