import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectTwo from '../../components/Project/SingleProjectTwo';

import projectImg1 from '../../assets/img/project/P1.jpg';
import projectImg2 from '../../assets/img/project/P4.jpg';
import projectImg3 from '../../assets/img/project/S1.jpg';
import projectImg4 from '../../assets/img/project/P4.jpg';
import projectImg5 from '../../assets/img/project/S3.jpg';
import projectImg6 from '../../assets/img/project/S2.jpg';
import projectImg7 from '../../assets/img/project/P7.jpg';
import projectImg11 from '../../assets/img/project/P11.jpg';

const Project = () => {

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style6 modify2 pt-80 pb-50">
                <div className="container">
                    <SectionTitle 
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Grow With Us"
                        titleClass="title title7 w-80"
                        title="Web and Mobile Application Development Solutions "
                        effectClass="heading-line"
                    />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg1} 
                                Title="FINTECH" 
                                Text="Make your clients digital banking and investing experience smooth without irritation like banking long queue lines." 
                                ProjectURL="./fintech-app-solution"
                            />
                        </div>
                                             
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg3} 
                                Title="E-COMMERCE" 
                                Text="Ensure your eCommerce sales are boosted with the most advanced on-demand fashion store app development solutions"
                                ProjectURL="./ecommerce-solutions" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg4} 
                                Title="BUSINESS APP" 
                                Text="Enterprise mobility solutions can help businesses increase revenue, improve customer service and overcome challenges."
                                ProjectURL="./business-app-solution" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg7} 
                                Title="FITNESS & WELLNESS" 
                                Text="Our healthcare app allows real-time communication with patients. Hospitals, doctors, pharmacists, etc. can use our health care mobile apps to better care for their patients."
                                ProjectURL="./fitness-wellness-app-solutions" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg11} 
                                Title="TRAVEL & TOURISM" 
                                Text="Develop custom travel apps for your business and increase profitability. Utilize travel mobile app development services for effective business outreach. Develop user-friendly travel apps with us."
                                ProjectURL="./travel-app-solutions" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg2} 
                                Title="SOCIAL MEDIA APP"
                                Text="Our social media app development services help clients harness the benefits of social media. With our expertise in social media app development, we are able to offer our clients globally future-ready solutions. "
                                ProjectURL="./social-media-app" 
                            />
                        </div>  
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg6} 
                                Title="HEALTHCARE " 
                                Text="Developing healthcare-specific applications for hospitals, doctors, pharmacies, etc. allows us to improve patient care."
                                ProjectURL="./healthcare-app-solution" 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mb-40">
                            <SingleProjectTwo 
                                itemClass="project-item"
                                projectImage={projectImg5} 
                                Title="REAL-ESTATE" 
                                Text="As an innovative real estate app development company, we always come up with innovative solutions for our clients."
                                ProjectURL="./real-estate-app-solution" 
                            />
                        </div>
                        
                       
                    </div>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Project