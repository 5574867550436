import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 pt-80 pb-50">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text style-bg"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our Web Development Services"
                        descClass="desc mb-25"
                        description="Hire website developers from metaqualt with a knack for identifying the solutions that can help you build a stronger presence in the market. As a globally recognized web development service provider, here is our rewarding set of services."
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                     <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className='row'>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Custom App Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Web Portal Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Enterprise App Development"
                                 />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-5 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="API & Web Integration"
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="SaaS Development"
                                />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="CMS Development"
                                 />
                            </div>
                        </div>
                    </div>
                   
                   
                </div>
            </div>
        </div>
    );
}

export default OurServices;