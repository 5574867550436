import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

import aboutPart1 from '../../assets/img/about/abut-1.webp';


import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

// import { LazyLoadImage } from "react-lazy-load-image-component";

const About = (props) => {
    return (
        <div id="rs-about" className="rs-about rs-rain-animate pt-150 pb-80 md-pt-60">
            <div className="container">
                <div className="row pb-0">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            <img className="main" src={aboutPart1} alt="" loading="lazy" /> 
                             {/* <LazyLoadImage 
                                src={aboutPart1}
                                width={600} 
                                height={400}
                                alt="Image Alt"
                            />  */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 pl-20 md-pt-40 sm-pt-40">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title2 mb-30"
                            subtitleClass="sub-text style-bg"
                            subtitle="About Us"
                            titleClass="title pb-25"
                            title="We Believe In By The People And For The People Strategy!"
                            descClass="desc pb-16"
                            description="Over 25 years working in IT services developing software applications and mobile apps for clients all over the world."
                            secondDescClass="desc pb-16"
                            secondDescription="We pursue our client's objectives with quality and fulfillment. We pick advancements/stages to such an extent that it suits our client's necessities and we can deliver things in ideal way. Our specialists are fit for working in a large portion of the innovations and platforms. We give Desktop/Mobile/Web/Small Business/Large Organizations arrangements and Our subjective investigation makes best solutions for our clients."
                            
                        />
                       
                    </div>
                </div>
                <div className="shape-image">
                    <img className="top dance" src={effectImg2} alt="" />
                    <img className="bottom dance" src={effectImg3} alt="" />
                </div>
                {/* counter area start */}
                <CounterHomeFive />
                {/* counter area end */}
                
            </div>
        </div>
        
    );
}

export default About;