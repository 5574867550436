import React from 'react';
import BannerTwo from '../../components/Banner/BannerTwo';
import Service from './ServiceSection';
import About from './AboutSection';
import ServiceFlip from './ServiceSectionFlip';
import CallUs from './CallUsSection';
import Process from './ProcessSection';
import Project from '../../components/Project';
import Testimonial from './TestimonialSection';
import Technology from '../../components/Technology';
import VideoStyleOne from '../../components/Video/VideoStyleOne';
import Blog from './BlogSection';
import CTA from '../../components/Common/CTA';
import Brand from '../../components/Common/Brand'
import ScrollToTop from '../../components/Common/ScrollTop';


const HomeThreeMain = () => {

	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerTwo />
			{/* banner-start */}			
			
			{/* About-area-start */}
			<About />
			{/* About-area-end */}

			{/* Service-area-start */}
			<Service />
			{/* Service-area-end */}

			{/* ServiceFlip-area-start */}
			<ServiceFlip /> 
			{/* ServiceFlip-area-end */}
			
			{/* project-area-start */}
			<Project />
			{/* project-area-end */}			
			
			{/* call us section start */}
			<CallUs />
			{/* call us section end */}	

			{/* Technology-area-start */}
			<Technology />
			{/* Technology-area-end */}
			
			{/* process-area-start */}
			<Process />
			{/* process-area-end */}

			{/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="./contact"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

			{/* Testimonial-area-start */}
			<Testimonial />
			{/* Testimonial-area-end */}

			{/* blog-area-start */}
			<Blog />
			{/* blog-area-end */}

			{/* brand-area-start */}
			<Brand />
			{/* brand-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeThreeMain;